import { Avatar, Box, Input } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import ParticipantsView from "./ParticipantsView";
import Settings from "../settings/Settings";
import React, { useState, useEffect, useRef } from "react";
import { ReduxConversation } from "../../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../../store/reducers/participantsReducer";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";

interface ConversationDetailsProps {
  convoSid: string;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
  updateConvoName: (title: string) => void;
}

const ConversationDetails: React.FC<ConversationDetailsProps> = (
  props: ConversationDetailsProps
) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const selfUser = useUser();
  const [isManageParticipantOpen, setIsManageParticipantOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(
    props.convo.friendlyName ?? props.convo.sid
  );
  const title = props.convo.sid != null ? props.convo.friendlyName  : props.convo.friendlyName;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [userDetails, setUserDetails] = useState<any>({});
  const handleEditClick = () => {
    if(userDetails.nickname){
      const encodedNickName = encodeURIComponent(userDetails.nickname);
      navigate('/socialHome/'+encodedNickName);
    }
    // setEditedText(props.convo.friendlyName ?? props.convo.sid);
    // setIsEditing(true);
  };
  const token = localStorage.getItem("sessionId");

  const handleInputChange = (convoName: string) => {
    setEditedText(convoName);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsEditing(false);
        if (editedText !== props.convo.friendlyName) {
          props.updateConvoName(editedText);
        }
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setIsEditing(false);
        if (editedText !== props.convo.friendlyName) {
          props.updateConvoName(editedText);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [editedText]);

  const getPrivacyName = (data: any) => {
    if (selfUser?.userProfileId === data?.id) {
        return data?.user?.firstName + " " + data?.user?.lastName;
    } else if (data?.userPrivacySettingsDTO?.identityPermission === 'Name is not visible. Instead just show nickname') {
        return data?.nickname;
    } else if (data?.userPrivacySettingsDTO?.identityPermission === 'Name is visible to all') {
        return data?.user?.firstName + " " + data?.user?.lastName;
    } else if (data?.userPrivacySettingsDTO?.identityPermission === 'Name is visible only to my connections. Other see nickname') {
        return data?.isConnected === true
            ? data?.user?.firstName + " " + data?.user?.lastName
            : data?.nickname;
    }
    else {
      return data?.user?.firstName + " " + data?.user?.lastName;
    }
}

  const fetchUserDetails = () => {
    if(!props.convo.uniqueName) return;
    try {
      // fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connections/my-connection/${user.id}?searchString=${userSearchString}&page=0&size=1000000`, {
      let userEmail = props.convo.uniqueName.replace(selfUser.email,'').replace('-','').replace('GIG@@@','');
      fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          "email": userEmail
        }),
      })
        .then((res) => {
          if (res.status > 400) {
            throw new Error("api_error");
          } else {
            return res.json();
          }
        })
        .then((data) => {
          if (data !== null) {
            //setUserDetails(data);
            const updatedData = { ...data, name: getPrivacyName(data) };
            setUserDetails(updatedData);
          } else {
            setUserDetails({});
          }
        })
        .catch((err) => {
          setUserDetails([]);
        });
    } catch (error) {
    }
  }

  const getTitle = () => {
    let title = props.convo.friendlyName ? (props.convo.friendlyName as string).replace((selfUser.firstName+' '+selfUser.lastName),'').replace(',','') : '';
    return title;
  }

  useEffect(() => {
    fetchUserDetails();

    return (() => {
      setUserDetails({});
    })
  }, [props.convo])

  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          color="colorText"
          // fontFamily="fontFamilyText"
          // lineHeight="lineHeight80"
          // fontWeight="fontWeightBold"
          maxHeight="100%"
          cursor="pointer"
          onClick={handleEditClick}
        >
          {isEditing ? (
            <Input
              type="text"
              value={editedText}
              onChange={(e) => handleInputChange(e.target.value)}
              ref={inputRef}
            />
          ) : (
            <>
              {
                (userDetails.profilePhotoUrl) ?
                  <Avatar
                      name={title as string}
                      src={`${process.env.REACT_APP_PICTURE_URL + userDetails.profilePhotoUrl }`}
                  />
                :  <Avatar
                      name={getTitle()}
                  />
              }
              <Box marginLeft="space30" >
                <Box fontWeight="fontWeightBold">
                  {/* {props.convo.friendlyName ?? props.convo.sid} */}
                  {/* {getTitle()} */}
                  {/*Name to change acc to privacy*/}
                  {/* { userDetails.user ? userDetails.user.firstName + ' ' + userDetails.user.lastName : getTitle() } */}
                  { userDetails.user ? userDetails.name : getTitle() }

                </Box>
                <Box 
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {userDetails.profileTitle ?? ' '}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* <ParticipantsView
            participants={props.participants}
            onParticipantListOpen={() => setIsManageParticipantOpen(true)}
          /> */}

          <Settings
            convo={props.convo}
            participants={props.participants}
            isManageParticipantOpen={isManageParticipantOpen}
            setIsManageParticipantOpen={setIsManageParticipantOpen}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationDetails;
