import { useState, ChangeEvent, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
	TextField,
	Button,
	Box,
	Paper,
	Grid,
	FormControl,
	FormHelperText,
	OutlinedInput,
	InputAdornment,
	IconButton,
	Typography,
	useTheme,
	useMediaQuery,
	Snackbar,
	AlertTitle,
	Alert,
	Autocomplete,
} from "@mui/material";
import CheckIcon from "../../images/check-fill.svg";
import CloseIcon from "../../images/close-line.svg";
import SuccessMark from "../../images/tick-circle.svg";
import VisibleOff from "../../images/eye-slash.svg";
import VisibleOn from "../../images/eye.svg";
import ErrorMark from "../../images/danger.svg";
import LogoWengor from "../../images/LuteFish_Logo_UI_withtagline_RGB.svg";
import RightAarrow from "../../images/right arrow.svg";
import { useGradient2Styles } from "../../theme";
import i18n from "../../l10n/strings.json";
import { Api } from "../../utils/ApiCalls/Api";
import SuccessIcon from "../../images/tick-square.svg";
import ErrorIcon from "../../images/danger.svg";
import { useUser } from "../../context/UserContext";
import ErrorAlert from "../Error/ErrorAlert";
import User from "../../services/user";
import { SignUpProvider, useSignUp } from "../../context/SignUpContext";
import DangerSymbol from "../../images/danger.svg";
import Loader from "../commonComponents/Loader";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

type RouterState = {
	email: string;
};

type SignUpContextType = {
	Code: string;
	Password: string;
	Repassword: string;
	FirstName: string;
	LastName: string;
	Location: string;
};

const UPPERCASEREGEX = /.*[A-Z].*/;
const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const initialUserData = {
	Code: "",
	Password: "",
	Repassword: "",
	FirstName: "",
	LastName: "",
	Location: "",
};

const SignUpMailSent = () => {
	localStorage.removeItem("user");
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const activationKey = searchParams.get("key");
	const location = useLocation();
	const token = localStorage.getItem("sessionId");
	const [searchString, setSearchString] = useState("");
	// const cities = useLocationAutocomplete(searchString);
	const user = useUser();
	const type: string = "";
	const { userData, setUserData } = useSignUp();
	const locationState = location.state as RouterState | undefined;
	const [firstName, setFirstName] = useState(userData.FirstName);
	const [lastName, setLastName] = useState(userData.LastName);
	const [email, setEmail] = useState<string>(locationState?.email as string);
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState(userData.Password);
	const [activated, setActivated] = useState(false);
	const [repassword, setRepassword] = useState(userData.Repassword);
	const [code, setCode] = useState(userData.Code);
	const [selectedLocation, setSelectedLocation] = useState(userData.Location);
	const [dirtyPassword, setDirtyPassword] = useState(false);
	const [dirtyRepassword, setDirtyRepassword] = useState(false);
	const [dirtyFirstName, setDirtyFirstName] = useState(false);
	const [dirtyLastName, setDirtyLastName] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setErrorMessage] = useState(false);
	const [showRenewPassword, setShowRenewPassword] = useState(false);
	const [showPassRequirement, setShowPassRequirements] = useState(false);
	const [confirmationMsg, setConfirmationMsg] = useState(
		i18n.signUp.confirmationCodeSentMsg
	);
	const [resendBtnDis, setResendBtnDis] = useState(false);
	const gradient = useGradient2Styles();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAction, setShowAction] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [authenticated, setAuthenticated] = useState(true);
	const [render, setRender] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/account/signup/getemail`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				activationKey: activationKey,
			}),
		})
			.then((response) => {
				if (response.status >= 400) {
					setRender(true);
					setIsError(true);
					setAuthenticated(false);
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((data) => {
				setIsError(false);
				// setEmail(data.email);
				// setAuthenticated(true);
				if ((data.activated == true && data.activationKey != null) && !isAdmin) {
					navigate("/socialProfileNew", {
						state: {
							email: data.email,
							password: "",
						},
					});
					// navigate("/login", { state: { showAlert: true } });
				} else if (data.activated == true && data.activationKey == null) {
					navigate("/home");
				} else {
					setRender(true);
					setEmail(data.email);
					setAuthenticated(true);
				}
			})
			.catch((error) => {
				console.log("error", error);
			});
	}, []);

	useEffect(() => {
		setUserData(initialUserData);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setConfirmationMsg("");
		}, 3000);
	}, [confirmationMsg]);
	const expectedBaseURL = process.env.REACT_APP_BASE_URL_FRONTEND;

	useEffect(() => {
		const currentBaseURL = `${window.location.href.split("#")[0]}#`;
		if (currentBaseURL !== expectedBaseURL) {
			window.location.replace(`${expectedBaseURL}/login`);
			navigate("/login");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${user.nickname}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status >= 400) {
				ErrorAlert(response.status, setAlertStatus, setShowAlert, setAlertMsg);
			} else {
				const data = await response.json();
				if (data.privacySettings.userProfile.user.userProfileId !== 0) {
					setIsDisabled(true);
					setShowAction(true);
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Account already created");
				}
			}
		} catch (error) {}
	};

	const handleBrowserBack = () => {
		fetchData();
	};
	window.onpopstate = handleBrowserBack;

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const isEnable = () => {
		if (
			// firstName.length > 0 &&
			// firstName.length <= 50 &&
			// lastName.length > 0 &&
			// lastName.length <= 50 &&
			password.length >= 8 &&
			password.length <= 16 &&
			repassword.length >= 8 &&
			repassword.length <= 16 &&
			// email.length > 0 &&
			// selectedLocation.length > 0 &&
			// code.length > 0 &&
			password === repassword &&
			UPPERCASEREGEX.test(password) &&
			NUMBEREREGEX.test(password) &&
			SPECIALCHAREREGEX.test(password)
		) {
			return false;
		} else {
			return true;
		}
	};

	const resetForm = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPassword("");
		setRepassword("");
		setCode("");
		setSelectedLocation("");
		setShowPassword(false);
		setShowRenewPassword(false);
		setDirtyPassword(false);
		setDirtyRepassword(false);
		setDirtyFirstName(false);
		setDirtyLastName(false);
		setIsError(false);
		setErrorMessage(false);
		setConfirmationMsg(i18n.signUp.confirmationCodeSentMsg);

		// Clear LocalStorage as well
		localStorage.removeItem(`signupFormData_${email}`);
	};

	const handleSignUpSuccess = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/authenticate`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				username: email,
				password: password,
			}),
		})
			.then((response) => {
				if (response.status >= 400) {
					setIsError(true);
					throw new Error();
				} else {
					setIsError(false);
				}
				return response.json();
			})
			.then((result) => {
				if (result.id_token != null) {
					const token = result.id_token;
					localStorage.setItem("sessionId", token);

					resetForm();

					if (result.multifactorAuthEnabled) {
						navigate("/codeVerify", {
							state: {
								email: email,
							},
						});
					} else {
						navigate("/home");
					}
				} else {
					navigate("/login");
				}
			});
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const signup = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const userLang = document.documentElement.lang;
		let item = {
			otp: code,
			location: selectedLocation,
			firstName,
			lastName,
			email,
			login: email,
			password,
			langKey: userLang,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/api/register`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(item),
		})
			.then((response) => {
				if (response.status >= 400) {
					setIsError(true);
					throw new Error();
				} else {
					return response;
				}
			})
			.then((result) => {
				setIsError(false);
				if (result) {
					handleSignUpSuccess();
				}
			})
			.catch((error) => {
				setIsError(true);
			});
	};

	const signUpMail = () => {
		// fetch(`${process.env.REACT_APP_BASE_URL}/api/generate-otp`, {
		// 	method: "POST",
		// 	// mode: "no-cors",
		// 	headers: { "Content-Type": "application/json" },
		// 	body: email,
		// })
		const otpDTO = {
			userEmail: email,
			otpType: "RESEND_OTP",
		};
		Api.callService
			.fetchApi(
				"POST",
				"generate-otp",
				null,
				JSON.stringify(otpDTO),
				true,
				false
			)
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result.status >= 400) {
					setErrorMessage(true);
				} else {
					setErrorMessage(false);
					setConfirmationMsg(i18n.signUp.reconfirmationCodeSentMsg);
					navigate("/sign-up", { state: { email: email } });
				}
			});
	};

	const validateOtp = () => {
		let item = {
			otp: code,
			userEmail: email,
		};
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/validate-otp-signup`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(item),
		})
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				const token = result.id_token;
				localStorage.setItem("sessionId", token);
				if (result.status === "BAD_REQUEST") {
					setIsError(true);
				} else {
					setErrorMessage(false);
					const data: SignUpContextType = {
						Code: code,
						Password: password,
						Repassword: repassword,
						FirstName: firstName,
						LastName: lastName,
						Location: selectedLocation,
					};

					setUserData(data);

					let formData = new FormData();
					// formData.append("firstName", "");
					// formData.append("lastName", "");
					formData.append("email", `testmail12111@gmail.com`);
					// formData.append("login", `thelkarac@gmail.com`);
					formData.append("password", "");
					// formData.append("location", "");
					// formData.append("otp", "");
					// formData.append("type", "");
					// fetch(`${process.env.REACT_APP_BASE_URL}/api/social-sign-up`, {
					fetch(
						`${process.env.REACT_APP_BASE_URL}/api/social-sign-up/password`,
						{
							method: "POST",
							body: formData,
						}
					)
						.then((res) => {
							if (res.status !== 200 && res.status !== 201) {
								ErrorAlert(
									res.status,
									setAlertStatus,
									setShowAlert,
									setAlertMsg
								);
							} else {
								return res.json();
							}
						})
						.then((data) => {
							if (data) {
								let data = {
									username: "thelkarac@gmail.com",
									password: "password",
									loginType: "social",
								};

								fetch(`${process.env.REACT_APP_BASE_URL}/api/new-login`, {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify(data),
								})
									.then((response) => {
										if (response.status >= 400) {
											navigate("/login");
										} else {
											return response.json();
										}
									})
									.then((result) => {
										if (result.errorKey === "accountDeactivated") {
											navigate("/login");
										}

										if (result.id_token != null && result.loginIdentifier) {
											if (!result.isAlreadyLoggedIn) {
												const token = result.id_token;
												localStorage.setItem("sessionId", token);	
												User.fetchUserInfo(() => {});
												navigate("/socialProfile", {
													state: {
														firstName: firstName,
														lastName: lastName,
														email,
														login: email,
														otp: code,
														location: selectedLocation,
														password: password,
														type: type,
													},
												});
												resetForm();
											}
										}
									});
							}
						});
				}
			});
	};

	const signupWithPassword = () => {
		//this is being called

		setErrorMessage(false);
		const data: SignUpContextType = {
			Code: code,
			Password: password,
			Repassword: repassword,
			FirstName: firstName,
			LastName: lastName,
			Location: selectedLocation,
		};

		setUserData(data);

		let formData = new FormData();
		formData.append("email", email);
		formData.append("password", password);
		//    fetch(`${process.env.REACT_APP_BASE_URL}/api/social-sign-up`, {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/social-sign-up/password`, {
			method: "POST",
			body: formData,
		})
			.then((res) => {
				//    if (res.status !== 200 && res.status !== 201) {
				if (res.status > 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data?.status === 400) {
					setAlertMsg(data.title);
					setAlertStatus(false);
					setShowAlert(true);
				} else {
					if (data) {
						let data = {
							username: email,
							password: password,
							loginType: "social",
						};

						// navigate("/socialProfileNew", {
						// 	state: {
						// 		firstName: firstName,
						// 		lastName: lastName,
						// 		email,
						// 		login: email,
						// 		otp: code,
						// 		location: selectedLocation,
						// 		password: password,
						// 		type: type,
						// 	},
						// });

						fetch(`${process.env.REACT_APP_BASE_URL}/api/new-login`, {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify(data),
						})
							.then((response) => {
								if (response.status >= 400) {
									navigate("/login");
								} else {
									return response.json();
								}
							})
							.then((result) => {
								if (result.errorKey === "accountDeactivated") {
									navigate("/login");
								}
								if (result.id_token != null && result.loginIdentifier) {
									if (!result.isAlreadyLoggedIn && !isAdmin) {
										const token = result.id_token;
										localStorage.setItem("sessionId", token);

										User.fetchUserInfo(() => {});
										navigate("/socialProfileNew", {
											state: {
												firstName: firstName,
												lastName: lastName,
												email,
												login: email,
												otp: code,
												location: selectedLocation,
												password: password,
												type: "social",
											},
										});
										resetForm();
									}
								}
							});
					}
				}
			})
			.catch((e) => {
				setAlertMsg("Error, Please try after some time.");
				setAlertStatus(false);
				setShowAlert(true);
			});
	};

	const handleSearchStringChange = (event: any) => {
		setSearchString(event.target.value);
	};

	const handleKeyDown = (event: any) => {
		// Prevent typing space in the password field
		if (event.key === " ") {
			event.preventDefault();
		}
	};

	const handleResendCode = () => {
		setTimeout(() => {
			setResendBtnDis(false);
		}, 3000);
		signUpMail();
	};

	//code field validation
	const exceptThisSymbols = ["e", "E", "+", "-", "."];

	useEffect(() => {
		setIsLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/account/signup/getemail`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				activationKey: activationKey,
			}),
		})
			.then((response) => {
				if (response.status >= 400) {
					setIsError(true);
					setAuthenticated(false);
					setIsLoading(false);
					throw new Error();
				} else {
					setAuthenticated(true);
					return response.json();
				}
			})
			.then((data) => {
				setIsError(false);
				// setEmail(data.email);
				// setAuthenticated(true);
				if (data.activated == true && data.activationKey != null) {
					setIsLoading(false);
					// navigate("/socialProfileNew", {
					// 	state: {
					// 		email: data.email,
					// 		password: "",
					// 	},
					// });
					navigate("/login", { state: { showAlert: true } });
				} else if (data.activated == true && data.activationKey == null) {
					setIsLoading(false);
					navigate("/home");
				} else {
					setEmail(data.email);
					setAuthenticated(true);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, []);
	return render ? (
		<SignUpProvider>
			{isLoading === true ? (
				<Loader />
			) : (
				<>
					{showAlert && (
						<Snackbar
							sx={{
								"&.MuiSnackbar-anchorOriginTopCenter1": {
									top: "70px",
								},
							}}
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={showAlert}
							autoHideDuration={6000}
							onClose={handleAlertClose}
						>
							<Alert
								onClose={handleAlertClose}
								icon={
									alertStatus ? (
										<img src={SuccessIcon} alt="error-icon" />
									) : (
										<img
											src={ErrorIcon}
											alt="error-icon"
											style={{ marginRight: "10px" }}
										/>
									)
								}
								sx={{
									backgroundColor: "#FFFFFF",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "6px",
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "black",
								}}
							>
								<AlertTitle
									sx={{
										fontSize: "1.125rem",
										fontWeight: "500",
										color: "#000000",
										display: "flex",
										margin: "auto",
										padding: "5px",
										justifyContent: "center !important",
									}}
								>
									{alertMsg}
								</AlertTitle>
							</Alert>
						</Snackbar>
					)}
					<form autoComplete="off" noValidate>
						<div
							className="column"
							style={{
								justifyContent: "center",
								margin: "0% 0% 2% 0%",
							}}
						>
							<div className="column">
								<div className="row" style={{ padding: "20px 30px 10px 30px" }}>
									<img src={LogoWengor} alt="LuteFish Logo"></img>
								</div>
								<Typography
									variant="h1"
									className="edit-heading-name"
									sx={{
										textAlign: "center",
										mt: "-90px",
										padding: "10px 0px 10px 0px",
										fontWeight: "bold",
										boxShadow: "0px 0px 6px #CCDEFB",
									}}
								>
									{i18n.signUp.titleNew}
								</Typography>
							</div>

							{/* {confirmationMsg !== "" && confirmationMsg.length > 0 ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								textAlign: "center",
								marginBottom: "20px",
							}}
						>
							<div style={{ marginRight: "10px" }}>
								<img src={SuccessMark} alt="success-mark" />
							</div>
							<Typography variant="h6" sx={{ color: "success.main" }}>
								{confirmationMsg}
							</Typography>
						</div>
					) : (
						""
					)} */}

							<div className="signupform">
								<Grid
									item
									xs={10}
									sm={7}
									md={5}
									// component={Paper}
									// elevation={5}
									// square
									sx={{
										scrollbarWidth: "none",
										overflowY: "auto",
									}}
								>
									<Box
										sx={{
											marginTop: "37px",
											marginBottom: 2,
											width: 600,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}
									>
										<FormControl sx={{ width: "350px" }}>
											{authenticated === true && isLoading === false ? (
												<>
													<Grid
														item
														xs={12}
														className="left-align"
														sx={{ width: "350px", mt: "20px", mb: "10px" }}
													>
														<Grid item={true} xs={1}>
															<Box mr={1}>
																<img
																	src={SuccessMark}
																	alt="error-mark"
																	height="32px"
																/>
															</Box>
														</Grid>
														<Grid item={true} xs={11}>
															<Typography
																className="signup-confirm-message"
																sx={{ color: "#259A12" }}
															>
																{i18n.signUp.emailVerified} <br />
																<span
																	className="signup-links-span-new"
																	style={{ color: "#000000" }}
																>
																	{email}
																</span>
																<span
																	className="signup-links-span-new"
																	style={{ color: "#7A7985" }}
																>
																	{i18n.signUp.usedAsLID}
																</span>
															</Typography>
														</Grid>
													</Grid>

													<Box my={0.5} mb={"20px"} mt={0}>
														<Typography
															className="signup-confirm-message"
															sx={{ mt: "20px", mb: "20px" }}
														>
															{i18n.signUp.createPassword}
														</Typography>
														<FormHelperText
															className="outlined-weight-helper-text"
															sx={{ mb: "9px", ml: "0px !important" }}
														>
															<Typography
																variant="subtitle1"
																sx={{ color: "customColor.label" }}
															>
																Password<span style={{ color: "red" }}>*</span>
															</Typography>
														</FormHelperText>
														<OutlinedInput
															id="outlined-adornment-password"
															type={showPassword ? "text" : "password"}
															sx={{
																background: "#F8F8F8",
																borderColor: "customColor.gray2",
																color: "customColor.black",
																width: "100%",
																height: "42px",
															}}
															inputProps={{
																style: {
																	height: 23,
																},
															}}
															onKeyDown={handleKeyDown}
															onChange={(e) => setPassword(e.target.value)}
															onFocus={() => {
																setDirtyPassword(true);
																setShowPassRequirements(true);
															}}
															onBlur={() => setShowPassRequirements(false)}
															onCopy={(e) => e.preventDefault()}
															onPaste={(e) => e.preventDefault()}
															onCut={(e) => e.preventDefault()}
															error={
																dirtyPassword &&
																(password.length < 8 ||
																	password.length > 16 ||
																	!UPPERCASEREGEX.test(password) ||
																	!NUMBEREREGEX.test(password) ||
																	!SPECIALCHAREREGEX.test(password))
															}
															value={password}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={() =>
																			setShowPassword((show) => !show)
																		}
																		onMouseDown={(
																			event: React.MouseEvent<HTMLButtonElement>
																		) => event.preventDefault()}
																		edge="end"
																	>
																		{showPassword ? (
																			<img src={VisibleOn} alt="eye" />
																		) : (
																			<img src={VisibleOff} alt="Eye Slash" />
																		)}
																	</IconButton>
																</InputAdornment>
															}
														/>
														{dirtyPassword &&
															(password.length < 8 || password.length > 16) && (
																<FormHelperText
																	sx={{
																		textAlign: "right",
																		fontSize: "13px",
																	}}
																>
																	<Typography
																		variant="body2"
																		sx={{ color: "error.light" }}
																	>
																		{password.length <= 0
																			? "Include at least one special character & number"
																			: password.length < 8 ||
																			  password.length > 16
																			? "Enter 8-16 character"
																			: "Include at least one special character & number"}
																	</Typography>
																</FormHelperText>
															)}
													</Box>
													<Box my={0.5} mb={"20px"} mt={0}>
														<FormHelperText
															sx={{
																marginLeft: "0px !important",
																mb: "9.74px",
															}}
														>
															<Typography
																variant="subtitle1"
																sx={{ color: "customColor.label" }}
															>
																Retype Password
																<span style={{ color: "red" }}>*</span>
															</Typography>
														</FormHelperText>
														<OutlinedInput
															id="outlined-adornment-password"
															type={showRenewPassword ? "text" : "password"}
															sx={{
																background: "#F8F8F8",
																borderColor: "customColor.gray2",
																color: "customColor.black",
																width: "100%",
																height: "41.3px",
															}}
															inputProps={{
																style: {
																	height: 23,
																},
															}}
															onKeyDown={handleKeyDown}
															onChange={(e) => setRepassword(e.target.value)}
															onFocus={() => setDirtyRepassword(true)}
															onCopy={(e) => e.preventDefault()}
															onPaste={(e) => e.preventDefault()}
															onCut={(e) => e.preventDefault()}
															error={
																dirtyRepassword &&
																(repassword.length < 8 ||
																	repassword.length > 16)
															}
															value={repassword}
															endAdornment={
																<InputAdornment position="end">
																	<IconButton
																		aria-label="toggle password visibility"
																		onClick={() =>
																			setShowRenewPassword((show) => !show)
																		}
																		onMouseDown={(
																			event: React.MouseEvent<HTMLButtonElement>
																		) => event.preventDefault()}
																		edge="end"
																	>
																		{showRenewPassword ? (
																			<img src={VisibleOn} alt="eye" />
																		) : (
																			<img src={VisibleOff} alt="Eye Slash" />
																		)}
																	</IconButton>
																</InputAdornment>
															}
														/>
														{dirtyRepassword && (
															<FormHelperText
																sx={{
																	textAlign: "right",
																}}
															>
																<Typography
																	variant="body2"
																	sx={{ color: "error.light" }}
																>
																	{dirtyRepassword && !repassword
																		? "Re-enter password"
																		: password !== repassword
																		? "Password does not match"
																		: ""}
																</Typography>
															</FormHelperText>
														)}
													</Box>

													<Box my={3}>
														{showAction ? (
															<Button
																sx={{ borderRadius: "24px", color: "gray" }}
																endIcon={
																	<img src={RightAarrow} alt="Right Arrow" />
																}
																fullWidth
																size="large"
																disabled={isDisabled}
																variant="contained"
															>
																<Typography>{i18n.signUp.title}</Typography>
															</Button>
														) : (
															<Button
																className={`submit-button ${gradient.gradient2}`}
																sx={{ borderRadius: "24px" }}
																endIcon={
																	<img src={RightAarrow} alt="Right Arrow" />
																}
																fullWidth
																size="large"
																disabled={isEnable()}
																variant="contained"
																// onClick={validateOtp}
																onClick={() => signupWithPassword()}
															>
																{/* <Typography>{i18n.signUp.title}</Typography> */}
																<Typography>Create Account</Typography>
															</Button>
														)}
													</Box>
												</>
											) : (
												<Grid
													item
													xs={12}
													className="left-align"
													sx={{ width: "350px", mt: "20px", mb: "10px" }}
												>
													<Grid item={true} xs={1}>
														<Box mr={1}>
															<img
																src={DangerSymbol}
																alt="error-mark"
																height="32px"
															/>
														</Box>
													</Grid>
													<Grid item={true} xs={11}>
														<Typography
															className="signup-confirm-message"
															sx={{ color: "#FD4B24" }}
														>
															{i18n.signUp.emailNotVefified} <br />
															<span
																className="signup-links-span-new"
																style={{ color: "#1D2D53" }}
															>
																{locationState?.email}
															</span>
														</Typography>
													</Grid>
												</Grid>
											)}
										</FormControl>
									</Box>
								</Grid>
							</div>
							{/* {
					showPassRequirement && dirtyPassword && (  */}

							{authenticated && (
								<div className="password-guidelines">
									<Typography
										variant="subtitle1"
										sx={{ color: "customColor.text" }}
									>
										Password requirements
									</Typography>
									<div className="password-validate">
										{password.length >= 8 ? (
											<img src={CheckIcon} alt="Check" />
										) : (
											<img src={CloseIcon} alt="Close" />
										)}
										<Typography
											variant="body4"
											sx={{ color: "customColor.text" }}
										>
											At least 8 characters
										</Typography>
									</div>
									<div className="password-validate">
										{UPPERCASEREGEX.test(password) ? (
											<img src={CheckIcon} alt="Check" />
										) : (
											<img src={CloseIcon} alt="Close" />
										)}
										<Typography
											variant="body4"
											sx={{ color: "customColor.text" }}
										>
											One capital letter
										</Typography>
									</div>
									<div className="password-validate">
										{NUMBEREREGEX.test(password) ? (
											<img src={CheckIcon} alt="Check" />
										) : (
											<img src={CloseIcon} alt="Close" />
										)}
										<Typography
											variant="body4"
											sx={{ color: "customColor.text" }}
										>
											One number
										</Typography>
									</div>
									<div className="password-validate">
										{SPECIALCHAREREGEX.test(password) ? (
											<img src={CheckIcon} alt="Check" />
										) : (
											<img src={CloseIcon} alt="Close" />
										)}
										<Typography
											variant="body4"
											sx={{ color: "customColor.text" }}
										>
											One special character !@#%$&
										</Typography>
									</div>
								</div>
							)}

							{/* )
				}  */}
						</div>
					</form>
				</>
			)}
		</SignUpProvider>
	) : (
		<div></div>
	);
};

export default SignUpMailSent;
