import { Button, Grid, Typography } from "@mui/material";
import Filter from "./Filter";
import Table from "./Table";
import UserTable from "./Table";
import { useManageFilter } from "./ManagementProvider/useManageFilter";
import { useAdminContext } from "./UserDetailsProvider";
import { Hourglass } from "react-loader-spinner";

const UserTab = () => {
	const {
		handleFilterReset,
		rowData,
		handleExport,
		downloadAllData,
		isDownloadComplete,
	} = useManageFilter();

	const handleDownloadAllAdminData = async () => {
		try {
			// Fetch all data
			await downloadAllData(2147483647);
		} catch (error) {
			console.error("Failed to fetch admin users data:", error);
		}
	};

	return (
		<Grid
			className="center-align mainPanel"
			spacing={{ xs: 1 }}
			sx={{ marginTop: "20px", margin: "auto" }}
		>
			<Grid
				container={true}
				className="column mainPanel"
				sx={{ marginBottom: "5vh" }}
				rowSpacing={{ xs: 1 }}
			>
				<Grid item={true}>
					<Grid container={true} columns={{ xs: 12 }} direction="row">
						<Grid
							item={true}
							xs={5}
							sx={{ paddingTop: "30px", paddingBottom: "20px" }}
						>
							<Typography
								sx={{
									fontStyle: "normal",
									fontWeight: "500",
									fontSize: "36px",
									lineHeight: "49px",
									color: "#000000",
									marginBottom: "10px",
								}}
							>
								User & Device Management
							</Typography>
						</Grid>
						<Grid
							item
							xs={7}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								alignContent: "flex-end",
								paddingTop: "30px",
								paddingBottom: "20px",
							}}
						>
							<Button
								sx={{
									borderRadius: "1.5rem", 
									padding: "0 1.25rem", 
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "100%", 
									maxWidth: "14.65rem", 
									height: "2.25rem", 
									whiteSpace: "nowrap", 
									backgroundColor: isDownloadComplete
										? "primary.main"
										: "#c0c0c0",
									color: isDownloadComplete ? "white" : "#808080",
									"&:disabled": {
										backgroundColor: "#e0e0e0",
										color: "#a0a0a0",
									},
								}}
								variant="contained"
								onClick={handleDownloadAllAdminData}
								disabled={!isDownloadComplete}
							>
								{isDownloadComplete
									? "Download All User Data"
									: "Downloading..."}
								{!isDownloadComplete && (
									<Hourglass
										visible={true}
										height="1.25rem" 
										width="1.25rem" 
										ariaLabel="hourglass-loading"
										wrapperStyle={{ marginLeft: "0.5rem" }} 
										colors={["#a0a0a0", "#d0d0d0"]}
									/>
								)}
							</Button>
						</Grid>
					</Grid>
					<Grid container columns={{ xs: 12 }} direction={"row"}>
						<Grid item xs={3} className="leftPanelWidth">
							<Filter />
						</Grid>
						<Grid item xs={9} className="centerPanelwoRightGig">
							<UserTable />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UserTab;
