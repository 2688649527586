import { useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	IconButton
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Like from "../../images/like-white-color.svg";

import { useNavigate } from "react-router-dom";
import DefaultEventImage from "../../images/eventDefault.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import calendarIcon from "../../images/calendar.svg";
import { format } from 'date-fns';
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useUser } from "../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		userCard: {
			borderRadius: "12px",
		},
		titleStyle: {
			fontFamily: "Lexend",
			fontSize: "1.125rem !important",
			fontWeight: "500",
			lineHeight: "normal",
			letterSpacing: "0em",
			textAlign: "left",
			marginTop: "2px",
			maxWidth:"170px",
			wordWrap:"break-word",
		},
		dateStyle: {
			fontFamily: "Lexend",
			fontSize: "13px",
			//fontSize: "14px",
			fontWeight: "500",
			lineHeight: "20px",
			letterSpacing: "0em",
			textAlign: "left",
			marginBottom: "0 !important",
			marginTop: "5px !important",
			paddingLeft: "3px",
			paddingTop: "0px",
		},
	})
);
// const Img = styled("img")({
// 	margin: "auto",
// 	display: "block",
// 	width: "198px",
// 	height: "198px",
// });
const EventCardCom = (props: any) => {
	const classes = useStyles();

	const [liked, setLiked] = useState(
		props.data.userReaction ? props.data.userReaction : false
	);
	const [likeCount, setLikeCount] = useState(props.data.likesCount);
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const maxAddressLength = 30;
	const maxTitleLength = 40;

	const navigate = useNavigate();

	const maxBioLength = 30;
	const String = `${props.data.venue} ${props.data.cityCountry}`;
	const concatenatedString = String.replace(/#/g, '');

	const viewDetails = () => {
		navigate(props.navigationLink + props.data.id);
	};

	 const utcTime: string = props.data.startDateTime;
	 const date = new Date(utcTime);

	const finalFormattedDate = format(date, "MMM dd, yyyy - hh:mm a");


	//const finalFormattedDate = format(date, "MMM dd - hh:mm a");

	const handleLike = (status: any) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/events-reaction/react`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				events: {
					id: props.data?.id,
				},
			}),
		})
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					status
						? setLikeCount(likeCount + 1)
						: setLikeCount(likeCount - 1);
						setLiked(status);
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
		<Grid
			sx={{
				width: 198,
				height: 364,
				cursor: "pointer",
				display: "flex",
				flexDirection: "column",
				border: "1px solid #CFD9DF",
				borderRadius: "12px",
			}}

		>
			<Grid container>
				<Grid item sx={{ position: "relative"}}>
					<img className="eventThumbnail"
						src={
							props.data.thumbnailUrl !== "" && props.data.thumbnailUrl !== "null" && props.data.thumbnailUrl !== null
								? process.env.REACT_APP_PICTURE_URL + props.data.thumbnailUrl
								: props.type === "gig"
								? process.env.REACT_APP_PICTURE_URL +
								  "/defaultimages/gig-thumbnail.svg"
								: props.type === "event"
								? process.env.REACT_APP_PICTURE_URL +
								  "/defaultimages/event-thumbnail.svg"
								: ""
						}
						alt="thumbnail"
						style={{
							borderTopLeftRadius: "12px",
							borderTopRightRadius: "12px",
							objectFit: "fill",
							width: "240px !important",
							height: "198px !important",
						}}
					/>

					<Grid
						sx={{
							position: "absolute",
							bottom: 0,
								left: 5,
								display: "flex",
								gap: "3px",
								mb: "1vh",
						}}
					>
						{liked ? (
								<IconButton
									color="primary"
									size="small"
									disabled={user.userAccountStatus === "SUSPEND"}
									onClick={() => {
										handleLike(false);
									}}
								>
									<ThumbUpIcon
										sx={{
											color: "#3F77D7",
											height: "18px",
											width: "18px"
										}}
									/>
								</IconButton>
							) : (
								// <img src={Like} alt="Like" style={{ width: "18px" }} />
								<IconButton
									color="primary"
									size="small"
									disabled={user.userAccountStatus === "SUSPEND"}
									onClick={() => {
										handleLike(true);
									}}
								>
									<img
										src={Like}
										alt="Like"
										style={{ width: "18px" }}
									/>
								</IconButton>
							)}
						<Typography
							sx={{
								fontFamily: "Lexend",
								fontStyle: "normal",
								fontWeight: "500",
								fontSize: "12px !important",
								lineHeight: "30px",
								display: "contents",
								color: "#FFFFFF",
							}}
						>
							{likeCount} {likeCount<=1 ? 'Like':'Likes'}
						</Typography>
					</Grid>
				</Grid>

				<Grid item style={{ height: "135px", padding: "2px 0px 0px 2px" }} onClick={viewDetails}>
					<CardContent style={{ flex: 1, marginTop: "0.1rem", padding:"1px 10px" }}>
						<Typography
							className={classes.titleStyle}
							sx={{
								maxWidth: '166px', 
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: 'nowrap'
							  }}
						>
							{/* {props.data.title} */}
							{props.data.title}
							 {/* && props.data.title.length > maxTitleLength
								? props.data.title.substring(0, maxTitleLength) + "..."
								: props.data.title} */}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								fontSize: "0.875rem",
								fontWeight:"500",
								lineHeight: "normal",
								// maxWidth:"170px",
								wordWrap:"break-word",
								maxWidth: "90%",
								overflow: 'hidden',
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
								WebkitLineClamp: 2
							}}
						>
							{concatenatedString}
							 {/* &&
							concatenatedString.length > maxAddressLength &&
							props.data.venue !== null
								? props.data.cityCountry === null
									? props.data.venue.substring(0, maxAddressLength) + "..."
									: concatenatedString.substring(0, maxAddressLength) + "..."
								: concatenatedString.substring(0, maxAddressLength) + "..."} */}
						</Typography>
						<Typography
							variant="body2"
							color="text.secondary"
							sx={{
								fontSize: "0.875rem",
								fontWeight:"400",
								letterSpacing:"-0.14px",
								lineHeight: "normal",
								// maxWidth:"170px",
								wordWrap:"break-word",
								marginTop:"1px",
								maxWidth: "90%",
								overflow: 'hidden',
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
								WebkitLineClamp: 2
							}}
						>
							{props.data.type === "EVENT"
								? props.data.description !== null &&
								  props.data.description !== "null"
									? props.data.description : ""
								: props.data.requirements &&
								  props.data.requirements !== "undefined"
								? props.data.requirements: ""
							}
						</Typography>

					</CardContent>
				</Grid>
			</Grid>
			<Grid item sx={{ marginTop: "-16px" }}>
			<CardActions>
					<Grid container>
						<Grid container>
							<Grid
								item
								xs={12}
								md={6}
								sx={{ padding: "0", display: "contents" }}
							>
								<img
									style={{ paddingLeft: "0px" }}
									src={calendarIcon}
									alt="Date"
								/>
								<Typography
									variant="body2"
									className={classes.dateStyle}
									sx={{
										maxWidth: '90%',
										overflow: "hidden",
										textOverflow: "ellipsis",
										whiteSpace: 'nowrap',
										fontSize: "13px",
										paddingRight: "0px"
									}}
								>
									{finalFormattedDate}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</CardActions>
			</Grid>
		</Grid>
		</>
	);
};
export default EventCardCom;
