import React, { useEffect, useState } from "react";
import { Client } from "@twilio/conversations";

import ConversationTitleModal from "../modals/ConversationTitleModal";
import { addConversation } from "../api";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { UserInterface } from "../../../types/types";
import DisabledProfile from "../../../images/profile-circle.svg";
import Profile from "../../../images/profile-circle-auto.svg";
import { useTheme } from "@mui/material/styles"; 
import { makeStyles } from "@mui/styles";
import { Combobox } from "@twilio-paste/core";
import { useUser } from "../../../context/UserContext";

interface NewConvoProps {
  client?: Client;
  collapsed: boolean;
}

const useStyles = makeStyles((theme: any) => ({

  root: {
      color: "white",
      '& .MuiAutocomplete-tag': {
          backgroundColor: '#0273E6',
          color: 'white',
    '&:hover': {
      backgroundColor: '#0273E6',
            color: 'white',
    }
      },
      '& .MuiSvgIcon-root': {
          color: 'white',
    '&:hover': {
      color: 'white'
    }
      },
  }
}));

const CreateConversationButton = (
  props: NewConvoProps
  ) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);
  const [filteredActiveUsers, setFilteredActiveUsers] = useState<
		Array<UserInterface> | []
	>([]);
  const attendeeIds:any = [];
  const token = localStorage.getItem("sessionId");
  const [chips, setChips] = useState<Array<UserInterface>>([]);
  const [formDisabled, setFormDisabled] = useState<Boolean>(true);
  const [title, setTitle] = useState<String>("");
  const user = useUser();

  useEffect(() => {
		fetch(
      // `${process.env.REACT_APP_BASE_URL}/api/active-users`, 
      `${process.env.REACT_APP_BASE_URL}/api/user-connections/my-connection/${user.id}?searchString=&page=0&size=10000`,
    {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
          
					const filteredUsers = data.filter((u: UserInterface) => {
						return !attendeeIds.includes(u.id);
					});
					setFilteredActiveUsers(filteredUsers);
          
				} else {
					setFilteredActiveUsers([]);
				}
			})
			.catch((err) => {
				setFilteredActiveUsers([]);
			});
	}, []);

  const handleAutocompleteChange = (
		event: any,
		item: any
	) => {
    
    // setTitle();
		// setChips([...item]);
		// setFormModified(true);
    //let newUser = item.firstName+' '+item.lastName;
    let newUser = item.name;
    
    addConversation(
      newUser,
      item.email,
      updateParticipants,
      props.client,
      addNotifications
    )
	};

  return (
    <>
      <Button fullWidth variant="secondary" onClick={handleOpen}>
        <PlusIcon decorative={false} title="Add convo" />
        {!props.collapsed ? "Start New Conversation" : null}
      </Button>
      {/* <Autocomplete
          onChange={handleAutocompleteChange}
          // multiple={false}
          placeholder="Create New Conversation"
          className="session-form"
          classes={classes}
          // disabled={formDisabled}
          options={filteredActiveUsers}
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName}`
          }
          filterSelectedOptions
          // defaultValue={_chips}
          // onInputChange={handleFilterChange}
          // onClick={createNewConversation}
          renderTags={(values, getTagProps) =>
            chips.map((value, index) => {
              const { key, className, ...tagProps } = getTagProps({
                index: index,
              });
              return (
                <Chip
                  key={key}
                  avatar={
                    formDisabled ? (
                      <img src={DisabledProfile} alt="profile" />
                    ) : (
                      <img src={Profile} alt="profile" />
                    )
                  }
                  className={`${className} label-chip`}
                  label={`${value.firstName} ${value.lastName}`}
                  sx={{
                    backgroundColor: formDisabled
                      ? "customColor.gray"
                      : "customColor.blue",
                    fontStyle: theme.typography.subtitle2.fontStyle,
                    fontSize: theme.typography.subtitle2.fontSize,
                    lineHeight: theme.typography.subtitle2.lineHeight,
                    fontWeight: theme.typography.subtitle2.fontWeight,
                    color: formDisabled
                      ? "customColor.label"
                      : "secondary.main",
                  }}
                  {...tagProps}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant={formDisabled ? "standard" : "outlined"}
              label="Create New Conversation"
            />
          )}
          sx={{
            backgroundColor: formDisabled
              ? "secondary"
              : "customColor.gray1",
            color: "customColor.black",
          }}
      /> */}
      
      {/* <Combobox
        autocomplete
        placeholder="Create new Conversation"
        items={filteredActiveUsers.map(user => {return user.firstName+ ' ' + user.lastName} )}
        labelText=""
        onInputValueChange={({inputValue}) => {
          if (inputValue !== undefined) {
            // setInputItems(artists.filter(item => item.toLowerCase().startsWith(inputValue.toLowerCase())));
            setTitle(inputValue.toLowerCase());
          }
        }}
      /> */}
      <ConversationTitleModal
        title=""
        type="new"
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onSave={async (title: string, uniqueName: string) => {
          const convo = await addConversation(
            title,
            uniqueName,
            updateParticipants,
            props.client,
            addNotifications
          );
          setIsModalOpen(false);
          updateCurrentConversation(convo.sid);
        }}
      />
    </>
  );
};

export default CreateConversationButton;
