import { useState, useEffect, useRef } from "react";
import * as React from 'react';
import {
    Button, Dialog, ListItem, List,
    FormControlLabel, FormControl, Typography, Divider, Box, Tabs, Radio, RadioGroup, TextField, InputAdornment, IconButton
} from '@mui/material';
import Tab from '@mui/material/Tab';
import ErrorAlert from "../Error/ErrorAlert";
import { useUser } from "../../context/UserContext";
import SearchIcon from '@mui/icons-material/Search';
import Close from '../../images/Posts/close.svg'
import CreatePostNew from "./CreatePostNew";
import { useAlertStatus } from "../../context/AlertStatusContext";

const SelectMusicFromHome = (props: any) => {

    const handleClose = () => {
        setMusicSelected('')
        // setMusic('')
        props.handleMusicClose(false)
    };
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [music, setMusic] = useState<any>('');
    const [musicSelected, setMusicSelected] = useState<any>('')
    const [selectedTab, setSelectedTab] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [alertMsg, setAlertMsg] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [alertStatus, setAlertStatus] = useState(false);
    const [musicList, setMusicList] = useState([]);
    const [openCreate, setOpenCreate] = useState(false)
    const [musicFromDevice, setMusicFromDevice] = useState<any>('')
    const inputFileRef = useRef<any>(null);
    const [deviceMusic, setDeviceMusic] = useState(false)
    const { updateAlertStatus } = useAlertStatus()
    const handlePostClose = () => {
        setOpenCreate(false)
    }
    const handleChangeMusic = (event: any) => {
        // const jsonObject = JSON.parse(event.target.value);
        setMusicSelected(event.target.value);
    };
    const handleSaveMusic = () => {
        let newMusicSelected=JSON.parse(musicSelected)
            if (newMusicSelected?.musicUrl?.includes("sig")){
            newMusicSelected.musicUrl = newMusicSelected.musicUrl.split('?')[0];
        }
        setMusic(newMusicSelected)
        handleClose();
        setOpenCreate(true)
    }
    const handleChange = (tabId: any) => {
        setSelectedTab(tabId);
    }

    const fetchMusicList = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/getAllMusic/filter`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                folderName: selectedTab === 0 ? 'MyMusic' : 'Recording',
                userId: user.id,
            }),
        })
            .then((res) => {
                if (res.status >= 400) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setMusicList(data)
                // setIsLoading(false);
            })
            .catch((err) => {
                // setShowAlert(true);
                // setAlertStatus(false);
                // setAlertMsg("Network Error! Please try again..");
                // setIsLoading(false);
            });
    };

    useEffect(() => {
        props.musicOpen && fetchMusicList()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, selectedTab])

    const [searchText, setSearchText] = useState('');
    const filteredItems = musicList && musicList.filter((item: any) =>
        item.fileName.toLowerCase().includes(searchText.toLowerCase())
    );

    const handleSearchChange = (event: any) => {
        setSearchText(event.target.value);
    };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleOpenEditor = (e: any) => {
        e.preventDefault();
        const file = e.target.files[0];

        //Convert bytes to MB & Round to the nearest integer
        var roundedFileSizeInMB = Math.round((file.size / (1024 * 1024))) ;
        // Convert MB back to bytes
        const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024); 

        if (file.type.startsWith('audio/')) {
            if (roundedFileSizeInBytes > (10 * 1024 * 1024)) {
                updateAlertStatus(true, false, 'Music size exceeds the maximum allowed limit of 10Mb. Please try again.');
            }else{
        setMusic({ musicUrl: URL.createObjectURL(file) });
        setMusicFromDevice(file);
        setDeviceMusic(true)
        handleClose();
        setOpenCreate(true)
        // setEditOpen(true);
        if (inputFileRef.current) {
            inputFileRef.current.value = null; // Clear the input value
            const newInput = document.createElement("input");
            newInput.type = "file";
            newInput.style.display = "none";
            newInput.addEventListener("change", handleOpenEditor);
            inputFileRef.current.parentNode.replaceChild(
                newInput,
                inputFileRef.current
            );
            inputFileRef.current = newInput;
        }
    }
    }
    else{
        updateAlertStatus(true, false, 'Invalid file type');
    }
    };

    return (
        <>
            <Dialog
                sx={{
                    border: "1px solid #888888",
                    boxShadow: "customColor.dropShadow",
                    borderRadius: "8px"
                }}
                fullWidth
                maxWidth="xs"
                open={props.musicOpen}
            >
                <Typography variant="h4" sx={{ px: 3, py: 1, pt: 2 }}>
                    Select Music
                </Typography>
                <Typography id="variant-modal-title" variant="body1" sx={{ px: 3, color: '#5B5B65' }}>
                    Select music for public listening
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'black',
                    }}
                >
                    <img src={Close} alt='close' />
                </IconButton>

                <TextField
                    variant="standard"
                    fullWidth
                    value={searchText}
                    placeholder="Search by file name"
                    onChange={handleSearchChange}
                    sx={{
                        '& .MuiInputBase-root .MuiInput-root': {
                            borderBottom: 'none'
                        },
                        '& .css-dnxhuh-MuiInputBase-root-MuiInput-root:before': {
                            borderBottom: 'none'
                        },
                        '& .css-1515x7o:before': {
                            borderBottom: 'none'
                        },
                        '& .css-dnxhuh-MuiInputBase-root-MuiInput-root:after': {
                            borderBottom: 'none'
                        },
                        '& .css-dnxhuh-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled,.Mui-error):before': {
                            borderBottom: 'none'
                        },
                        '& .css-1515x7o:after': {
                            borderBottom: 'none'
                        },
                        '& .css-1515x7o:hover:not(.Mui-disabled,.Mui-error):before': {
                            borderBottom: 'none'
                        },
                        mt: 1,
                        height: '34px',
                        width: '70%',
                        mx: 3,
                        backgroundColor: '#F3F3F3', color: '#888888', border: 'none', borderRadius: '20px',
                        //  alignSelf: 'center',
                        borderColor: 'white'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ pl: 2 }}>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Tabs
                    value={selectedTab}
                    onChange={(event, value) => handleChange(value)}
                    aria-label="Plain tabs" sx={{
                        alignSelf: 'center',
                        '& .Mui-selected': {
                            color: '#5B5B65 !important'
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#5B5B65 !important'
                        },
                        color: '#5B5B65'
                    }}>
                    <Tab key={0} sx={{ textTransform: 'none !important' }} label="Uploaded Music" />
                    {/* Hiding for social-site launch */}
                    {
                        // user.isCollaborationActive === true ?  
                        <Tab key={1} sx={{ textTransform: 'none !important' }} label="Jam Recordings" /> 
                        // : <></>
                    }
                </Tabs>

                <Divider variant="middle" light sx={{ my: 1 }} />

                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={musicSelected}
                        onChange={(event: any) => handleChangeMusic(event)}
                        sx={{ mx: 2 }}
                    >
                        <List
                            component="div"
                            sx={{ height: 320, overflowY: 'auto' }}>
                            {/* {filteredItems.length > 0 ?
                                filteredItems.map((value: any, index) => ( */}
                            {filteredItems && filteredItems.length > 0 ?
                                filteredItems.map((value: any, index) => (
                                    <React.Fragment key={value.id}>
                                        <ListItem>
                                            <FormControlLabel control={<Radio />} value={JSON.stringify(value)} label={value.fileName} />
                                        </ListItem>
                                    </React.Fragment>
                                ))
                                :
                                <Typography sx={{ textAlignLast: 'center' }}>
                                    No records available
                                </Typography>
                            }
                        </List>
                    </RadioGroup>
                </FormControl>

                <Divider variant="middle" light sx={{ my: 1 }} />

                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, flexDirection: 'row-reverse', mx: 2, mb: 2 }}>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                        onClick={handleSaveMusic}
                        disabled={musicSelected === ''}
                    >
                        {props.comment ? 'Save' : 'Next'}
                    </Button>
                    <Button
                        sx={{ borderRadius: '17px' }}
                        size="small"
                        variant="outlined"
                        onClick={handleClose}
                    >Cancel
                    </Button>

                    {/* <Button
                            size="small"
                            variant="contained"
                            sx={{ borderRadius: '17px', background: '#FF702A', px: 2, mr: 'auto' }}
                        // onClick={handleSaveMusic}
                        > */}
                    {/* <Box className='upload-Multiple' id="photo-upload" sx={{
                                borderRadius: '17px',
                                border: '1px solid #0273E6',
                                color: '#0273E6',
                                fontSize: '0.9rem !important',
                                px: 1,
                                width: 'fit-content',
                                marginLeft: 'auto',
                                height: '36px !important',
                                minHeight:'36px !important',
                                // maxHeight: '36px !important',
                                display: 'content',
                                padding:'3px 9px',
                                cursor:'pointer',
                                // pt: '4px',
                                // pb: '5px'
                            }}
                            >Upload from device</Box> */}

                    {/* </Button> */}
                    <label style={{marginRight:'auto',alignSelf:"center"}}>
                        <Typography sx={{fontSize:'0.9rem', color:'#0273E6', cursor:'pointer',ml:1}}>Browse Music</Typography>
                        <input
                            id="photo-upload"
                            type="file"
                            ref={inputFileRef}
                            accept="audio/*,.mp3,.wav,.mpeg"
                            style={{ display: "none" }}
                        // multiple
                        onChange={handleOpenEditor}
                        />
                    </label>
                </Box>
            </Dialog>
            <CreatePostNew
                open={openCreate}
                handleClose={handlePostClose}
                resetPostDetails={props.resetPostDetails}
                postType='MUSIC'
                mediaUrl={music ? music.musicUrl : ''}
                musicId={music ? music.id : ''}
                musicFromDevice={musicFromDevice}
                deviceMusic={deviceMusic}
            />
        </>
    );
}

export default SelectMusicFromHome;
