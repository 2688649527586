import { useState, useEffect } from "react";

const useGenresAndInstruments = (request: string) => {
	const [instruments, setInstruments] = useState<any[]>([]);
	const [alertMessage, setAlertMessage] = useState<string>("");
	const [statusOfAlert, setStatusOfAlert] = useState<boolean>(false);

	const genres: any[] = [
		{ title: "Americana" },
		{ title: "Avant-garde" },
		{ title: "Baroque" },
		{ title: "Big Band" },
		{ title: "Bluegrass" },
		{ title: "Blues" },
		{ title: "Bollywood" },
		{ title: "Broadway/Showtunes" },
		{ title: "Chamber-Music" },
		{ title: "Classic Rock" },
		{ title: "Classical" },
		{ title: "Contemporary Classical" },
		{ title: "Country" },
		{ title: "Disco" },
		{ title: "Drumline" },
		{ title: "EDM" },
		{ title: "Electronic/Dance" },
		{ title: "Folk" },
		{ title: "Funk" },
		{ title: "Gospel" },
		{ title: "Hip-hop/Rap" },
		{ title: "House" },
		{ title: "Indie/Alternative" },
		{ title: "Jazz" },
		{ title: "K-Pop" },
		{ title: "Latin" },
		{ title: "Metal" },
		{ title: "Minimalism" },
		{ title: "New Age" },
		{ title: "Opera" },
		{ title: "Orchestral" },
		{ title: "Pop" },
		{ title: "Punk" },
		{ title: "R&B" },
		{ title: "Ragtime" },
		{ title: "Reggae" },
		{ title: "Reggaeton" },
		{ title: "Religious" },
		{ title: "Rock" },
		{ title: "Romantic" },
		{ title: "Salsa" },
		{ title: "Ska" },
		{ title: "Soul" },
		{ title: "Soundtrack" },
		{ title: "Swing" },
		{ title: "Symphonic" },
		{ title: "Techno" },
		{ title: "Wind Ensemble" },
	];

	const fetchInstruments = async () => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			if (res.status >= 400 && res.status < 500) {
				setAlertMessage("Something went wrong! Please try again..");
				setStatusOfAlert(false);
			} else {
				const data = await res.json();
				setInstruments(data);
			}
		} catch (err) {
			setAlertMessage("Network Error! Please try again..");
			setStatusOfAlert(false);
		}
	};

	useEffect(() => {
		if (request === "instruments") {
			fetchInstruments();
		}
	}, [request]);

	switch (request) {
		case "Genres":
			return [genres, [], "", false] as const;
		case "GenresAndInstruments":
			return [genres, instruments, alertMessage, statusOfAlert] as const;
		default:
			return [genres, instruments, alertMessage, statusOfAlert] as const;
	}
};

export default useGenresAndInstruments;
