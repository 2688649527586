import { ca } from "date-fns/locale";
import { EventEmitter } from "events";

declare global {
	interface Window {
		pc: any;
	}
}

class WebsocketMetricsClient extends EventEmitter {
	public websocketClient: WebSocket | undefined = undefined;
	private datachannel: RTCDataChannel | undefined = undefined;
	private peerConnection: RTCPeerConnection | undefined = undefined;
	public sessionStatus: string = "";
	public candidates:any[]=[];

 constructor() {
  super();
//   this.setMaxListeners(20);
 }

	private IsValidJSON(test: string): any {
		try {
			const obj = JSON.parse(test);
			if (obj && typeof obj === "object" && obj !== null) {
				return obj;
			}
		} catch (e) {
			console.error("Invalid JSON:", e);
		}
		return undefined;
	}

	public async onWebSocketOpen() {
		console.log("WebSocket connection opened");

		if (this.websocketClient) {
			this.candidates=[]
			this.websocketClient.onmessage = async (message: {
				data: { text: () => string | PromiseLike<string> };
			}) => {
				try {
					const testData = await message.data.text();
					const event = JSON.parse(testData);
					//console.log("event",event);
					switch (event.type) {
						case "sdp":
							if (this.peerConnection) {
								await this.peerConnection.setRemoteDescription({
									type: "answer",
									sdp: event.description,
								});
								
								this.candidates.forEach(async (candidate1) =>{

									try {
										const rtcCndidate = new RTCIceCandidate(candidate1);
									//	console.log("candidates received :", rtcCndidate);
										await this.peerConnection?.addIceCandidate(rtcCndidate);
										console.log("Added ICE candidate");
									} catch (e) {
										console.error("Error adding ICE candidate:", e);
									}
								});
							}
							break;
						case "candidate":
							if (this.peerConnection) {
								const candidate = {
									candidate: event.candidate,
									sdpMid: event.mid,
									sdpMLineIndex: event.mLineIndex || 0,
								};
								console.log(candidate);

								if( this.peerConnection.remoteDescription){
									try {
										await this.peerConnection.addIceCandidate(candidate);
										console.log("Added ICE candidate");
									} catch (e) {
										console.error("Error adding ICE candidate:", e);
									}
								}
								this.candidates.push(candidate);
								
							}
							break;
					}
				} catch (e) {
					console.error("Error handling WebSocket message:", e);
				}
			};

			await this.sendOffer();
		}
	}

	public async createMetricsClientConnection(data: any): Promise<void> {
		if (this.websocketClient) {
			this.websocketClient.close();
			this.websocketClient = undefined;
		}

		const url = `${process.env.REACT_APP_RELAY_SERVER_URL}/${data?.sessionID}/${data?.userID}`;
		this.websocketClient = new WebSocket(url);

		console.log( process.env);
		console.log( url);

		this.websocketClient.addEventListener("open", (event) => {
			console.log("Hello Server!");
			this.onWebSocketOpen();
		});

		this.peerConnection = new RTCPeerConnection({
			iceServers: [
				{ urls: "stun:stun.l.google.com:19302" },
				{ urls: "stun:stun2.l.google.com:19302" },
				{ urls: "stun:stun3.l.google.com:19302" },
				{ urls: "stun:stun4.l.google.com:19302" },
				// { urls:"turn:relay1.expressturn.com:3478",
				// 	username:"efODI3QNQBSFUXMD9V",
				// credential:"Ivh0DaBJ2iUm6Cjb"}
			],
			// "iceTransportPolicy":"relay"
		});

		this.peerConnection.addEventListener(
			"connectionstatechange",
			(event) => {
				switch (this.peerConnection?.connectionState) {
					case "new":
					case "connecting":
						console.log("Connecting…");
						break;
					case "connected":
						console.log("Online");
						break;
					case "disconnected":
						console.log("Disconnecting…");
						break;
					case "closed":
						console.log("Offline");
						break;
					case "failed":
						console.log("Error");
						console.log(event);
						break;
					default:
						console.log("Unknown");
						break;
				}
			},
		);

		this.datachannel = await this.peerConnection.createDataChannel(
			crypto.randomUUID()
		);

		this.datachannel.binaryType="arraybuffer";

		this.datachannel.addEventListener("open", (event) => {
			console.log(event);
		});

		this.datachannel.onopen = () => {
			console.log("Data channel opened");
		};

		this.datachannel.onerror = (error) => {
			console.error("Data channel error:", error);
		};

		this.datachannel.onmessage = (e: MessageEvent) => {
			if (e.data) {
				console.log( e);
				const text = new TextDecoder("utf-8").decode(e.data);
				 
				text.split("\n").forEach((line) => {
					const parsed = this.IsValidJSON(line.replace("\0", ""));
					if (parsed) {
						if (parsed.id === 0) {
							// latency
							this.emit("latency", {
								metadata: {
									created_by: parsed.device,
									latency: parsed.reading,
								},
							});
						} else {
							// volume
							this.emit("audioDetails", {
								metadata: {
									created_by: parsed.device,
									decibel_level: parsed.reading,
								},
							});
						}
					}
				});
			}
		};

		// this.websocketClient.onopen = this.onWebSocketOpen;

		this.websocketClient.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		this.websocketClient.onclose = () => {
			console.log("WebSocket connection closed");
		};

		this.peerConnection.onicecandidate = async (
			event: RTCPeerConnectionIceEvent
		) => {
			const { candidate } = event;
			console.log( 'local candidate discovered:', candidate);
			if (
				candidate &&
				this.websocketClient &&
				this.websocketClient.readyState === WebSocket.OPEN
			) {
				this.websocketClient.send(
					JSON.stringify({
						type: "candidate",
						candidate: candidate.candidate,
					})
				);
			}
		};
	}

	private async sendOffer(): Promise<void> {
		try {
			if (this.peerConnection) {
				const offer = await this.peerConnection.createOffer();
				await this.peerConnection.setLocalDescription(offer);

				if (
					this.peerConnection.localDescription &&
					this.websocketClient?.readyState === WebSocket.OPEN
				) {
					const message = {
						type: "offer",
						sdp: this.peerConnection.localDescription.sdp,
					};
					this.websocketClient.send(JSON.stringify(message));
				}
			}
		} catch (error) {
			console.error("Error sending offer:", error);
		}
	}

	public disconnectWebsocketConnection(): void {
		console.log("Disconnecting!");
		if (this.datachannel) {
			this.peerConnection = undefined;
			this.datachannel = undefined;
			if (this.websocketClient) {
				this.websocketClient.close();
				this.websocketClient = undefined;
			}
		}
	}
}

export default new WebsocketMetricsClient();
