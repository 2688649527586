import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useUser } from "../../context/UserContext";
import User from "../../services/user";

const MultipleImageUpload = (props: any) => {

    const user = useUser();

    const [storageConsumedPerc, setStorageConsumedPerc] = useState(0);
	const [storageConsumed, setStorageConsumed] = useState(0);
	const [storageAvailable, setStorageAvailable] = useState(0);

    type Units = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';

	const getReadableFileSizeString = (fileSize: number, unit: Units = 'B') => {
	  if (fileSize) {
		let i = -1;
		const byteUnits = [
		  " KB",
		  " MB",
		  " GB",
		  " TB",
		  " PB",
		  " EB",
		  " ZB",
		  " YB"
		];
	
		const unitsMap: Record<Units, number> = {
		  'B': 0,
		  'KB': 1,
		  'MB': 2,
		  'GB': 3,
		  'TB': 4,
		  'PB': 5,
		  'EB': 6,
		  'ZB': 7,
		  'YB': 8
		};
	
		const upperUnit = unit.toUpperCase() as Units;
		if (upperUnit in unitsMap) {

			i = unitsMap[upperUnit] - 1;
			fileSize = fileSize * Math.pow(1024, i );
		 
		  i--; // Adjust to start with the correct unit for the conversion loop
		}
	
		do {
		  fileSize = fileSize / 1024;
		  i++;
		} while (fileSize > 1024 && i < byteUnits.length - 1);
	
		return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
	  } else {
		return '0 MB';
	  }
	};

    useEffect(() => {
   
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {
				
				if (user.storageCapacity != null) {
                    let converterSize: any = getReadableFileSizeString(userInfo.storageUsage, 'MB');
                    setStorageConsumed(converterSize);
                    // Set Storage as 50 GB default untill avaialble from backend
                    let totalSize: any = getReadableFileSizeString(userInfo.storageCapacity as any, 'MB')
                    setStorageAvailable(totalSize);
    
                    let consumptionPercent = (userInfo.storageUsage / (userInfo.storageCapacity as any)) * 100;
                    setStorageConsumedPerc(consumptionPercent);
                }
			}
		});
    }, [])

    const canStore = Number(storageConsumedPerc) < 99.99
    return (
        <>
            {props.imagesLength < 10 && user.userAccountStatus !== "SUSPEND"  && canStore ?
                <label title="Upload Photo"
                    style={{ height: '30px' }}
                >
                    <Box className='upload-Multiple' id="photo-upload" sx={{
                        borderRadius: '20px',
                        border: '1px solid #0273E6',
                        color: '#0273E6',
                        fontSize: '16px !important',
                        px: 1,
                        width: 'fit-content',
                        marginLeft: 'auto',
                        height: '30px !important',
                        maxHeight: '30px !important',
                        display: 'unset',
                        pt: '4px',
                        pb: '5px'
                    }}
                    >

                        Upload Photos</Box>
                    <input
                        ref={props.inputFileRef}
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        // multiple
                        onChange={(e) => props.photoUpload(e)} />
                </label>
                :
                <label title="Upload Photo"
                    style={{ height: '30px' }}
                >
                    <Box className='upload-Multiple-disable' id="photo-upload" sx={{
                        borderRadius: '20px',
                        border: '1px solid #F5F5F5',
                        color: 'gray',
                        fontSize: '16px !important',
                        px: 1,
                        width: 'fit-content',
                        marginLeft: 'auto',
                        height: '30px !important',
                        maxHeight: '30px !important',
                        display: 'unset',
                        pt: '4px',
                        pb: '5px'
                    }}
                    >Upload Photos</Box>               
                </label>
            }
        </>
    )
}
export default MultipleImageUpload