import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	FormHelperText,
	TextField,
	Button,
	AlertTitle,
	Alert,
	Snackbar,
	Box,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TypographyProps } from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import Next from "../../../images/next.svg";
import AdminDetails from "./AdminDetails";
import AssignUserRole from "../../../images/profile-2user-r.svg";
import AssignRole from "../../../images/profile-2user-rf.svg";
import AIAvatarPrompt from "../../../images/profile-circle.svg";
import AvatarPrompt from "../../../images/profile-circle11.svg";
import AdminUsers from "../../../images/admin-users-icon.svg";
import ActionDialog from "../../reusable-components/ActionDialog";
import RadioGroup from "@mui/material/RadioGroup";
import i18n from "../../../l10n/strings.json";
import { FC } from "react";
import Radio, { RadioProps } from "@mui/material/Radio";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import useFetch from "../../../hooks/useFetch.tsx/useFetch";
import { useAdminContext } from "../ManageUserDashBoard/UserDetailsProvider";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import BackAarrow from "../../../images/BackArrow.svg";
import { Hourglass } from "react-loader-spinner";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			borderRadius: "18px !important",
		},
		promptBtn: {
			width: "100%",
			// background: (user.userAccountStatus == "ACTIVE") ? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" : "linear-gradient(126.25deg, rgba(0,0,0,0.2) 3.77%, rgba(0,0,0,0.2) 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		btnStyles: {
			fontSize: "16px",
			fontWeight: "500",
			color: "#fff",
		},
	})
);

const CustomTypography = styled(Typography)({
	color: "#5D5D65",
	fontSize: "2rem",
});

type RouteParams = {
	menuId: string;
};

interface CustomTypographyProps extends TypographyProps {
	variant?: "body1" | "body2";
	component?: "h6" | "p";
}

function CircleRadio(props: RadioProps) {
	return (
		<Radio
			disableRipple
			color="primary"
			checkedIcon={<CheckCircleRoundedIcon />}
			{...props}
		/>
	);
}

const HeadingTypo: FC<CustomTypographyProps> = styled(
	Typography
)<CustomTypographyProps>({
	color: "#000",
	fontSize: "1rem",
	fontWeight: "500",
});

const SubHeadingTypo: FC<CustomTypographyProps> = styled(
	Typography
)<CustomTypographyProps>({
	color: "#5B5B65",
	fontSize: "0.875rem",
	fontWeight: "400",
});

const AdminSettings = () => {
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const param = useParams<RouteParams>();
	const theme = useTheme();
	const navigate = useNavigate();

	const [menus, setMenus] = useState([
		// {
		// 	title: "Assign Role",
		// 	icon: AssignUserRole,
		// 	activeIcon: AssignRole,
		// 	id: "assignRole",
		// 	disabled: false,
		// },
		{
			title: "Avatar Prompt",
			icon: AvatarPrompt,
			activeIcon: AIAvatarPrompt,
			id: "avatarprompt",
			disabled: false,
		},
		{
			title: "Admin Users",
			icon: AdminUsers,
			activeIcon: AdminUsers,
			id: "adminUsers",
			disabled: false,
		},
	]);
	const [activeMenu, setActiveMenu] = useState(
		param.menuId ?? `${menus[0].id}`
	);
	const [activeMenuTitle, setActiveMenuTitle] = useState(
		param.menuId ?? `${menus[0].title}`
	);
	const [avatarPromptOpen, setAvatarPromptOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const isSuperAdmin: boolean = useAdminAuthorized("isSuperAdmin");
	const [invalidCharacterError, setInvalidCharacterError] = useState(false);

	const {
		isEditing,
		setIsEditing,
		selectedRow,
		getAllAdminUsers,
		selectedButton,
		handleExport,
		rowsData,
		selectedUsername,
		isDownloadComplete,
		setIsDownloadComplete,
	} = useAdminContext();

	const { updateAlertStatus } = useAlertStatus();

	const [adminCreationInfo, setAdminCreationInfo] = useState({
		firstName: "",
		email: "",
		adminRoleType: "",
		updatedEmail: "",
	});

	useEffect(() => {
		if (isEditing && selectedRow) {
			setAdminCreationInfo({
				...adminCreationInfo,
				firstName: selectedRow.name,
				email: selectedRow.email,
				adminRoleType: selectedRow.adminRoleType,
				updatedEmail: "",
			});
		}
	}, [isEditing, selectedRow]);

	const {
		data: userData,
		loading: isFetchingUserCreate,
		error: userError,
		triggerFetch: triggerUserFetch,
	} = useFetch<{ errorKey: string; message: string }>();

	const changeMenu = (value: any) => {
		setActiveMenu(value.id);
		let currentActiveMenuTitle = menus.find((menu) => {
			return menu.id === value.id;
		});
		setActiveMenuTitle(value!.title);
	};

	const handleDialogClose = () => {
		setAvatarPromptOpen(false);
	};

	const handleClickOpen = () => {
		setAdminCreationInfo({
			firstName: "",
			email: "",
			adminRoleType: "",
			updatedEmail: "",
		});
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setIsEditing(false);
	};

	const handleCreateUser = (userData: any) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(userData),
		};
		triggerUserFetch(
			`${process.env.REACT_APP_BASE_URL}/api/admin/action-admin-user?action=${
				isEditing ? "EditAdminUser" : "CreateAdminUser"
			}`,
			requestOptions
		);
	};

	useEffect(() => {
		if (userData || userError) {
			if (userData) {
				if (userData.errorKey === "success") {
					updateAlertStatus(
						true,
						true,
						userData?.message || "User created successfully"
					);
					getAllAdminUsers("", selectedButton);
					if (isEditing) {
						setIsEditing(false);
					}
					setAdminCreationInfo({
						firstName: "",
						email: "",
						adminRoleType: "",
						updatedEmail: "",
					});
					handleClose();
				} else if (userData.errorKey === "alreadyExists") {
					updateAlertStatus(true, false, "User with this email already exists");
				}
			} else if (userError) {
				updateAlertStatus(true, false, "Internal server error..");
			}
		}
	}, [userData, userError]);

	const { adminScreenShow, setadminScreenShow } = useAdminContext();

	const handleEmailBlur = () => {
		validateEmail();
	};

	const validateEmail = () => {
		const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
			adminCreationInfo.email
		);
		setEmailError(!isValidEmail && adminCreationInfo.email.length > 0);
	};

	// if (!adminScreenShow) {
	// 	return null;
	//   }
	const backToDashboard = () => {
		setadminScreenShow(false);
		navigate(`/admin-settings/`);
		//navigate('/admin-dashboard');
	};

	const handleDownloadAllAdminData = async () => {
		try {
			setIsDownloadComplete(false);
			const data = await getAllAdminUsers("", selectedButton, 2147483647);
			handleExport(data);
		} catch (error) {
			console.error("Failed to fetch admin users data:", error);
		}
	};

	return (
		<>
			<Grid className="mainPanel">
				<Grid container={true} className="column jam-session mainPanel">
					<Grid
						container
						xs={12}
						sx={{
							backgroundColor: "white",
							zIndex: "1",
							paddingTop: "25px",
							position: "sticky",
						}}
					>
						{!adminScreenShow ? (
							<>
								<Grid item xs={activeMenu !== "adminUsers" ? 3 : 7.5} py={2}>
									<Typography
										className="home-title-custom-size"
										style={{
											fontWeight: "500",
											fontSize: "36px !important",
											lineHeight: "49px",
											color: "#000000",
										}}
									>
										{activeMenu !== "adminUsers"
											? "App Settings"
											: "Admin User management"}
									</Typography>
								</Grid>
							</>
						) : (
							<Grid item xs={7.5} py={2}>
								<Typography
									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "18px",
										color: "#000000",
										cursor: "pointer",
									}}
								>
									<img
										src={BackAarrow}
										alt="back"
										onClick={() => backToDashboard()}
									/>
									{"   Back to Dashboard :  "}
									{selectedUsername}'s Activity Log
								</Typography>
							</Grid>
						)}

						{activeMenu === "avatarprompt" ? (
							<>
								<Grid item xs={6.9}>
									<Typography
										sx={{
											flex: "1 1 100%",
											marginTop: "35px",
											marginLeft: "5px",
										}}
										variant="h5"
										id="tableTitle"
										component="div"
									>
										Avatar Image Generation Prompt
									</Typography>
								</Grid>
								<Grid item xs={2} alignSelf="right">
									<label>
										<Box
											className={classes.promptBtn}
											id="photo-upload"
											sx={{
												height: "28px",
												fontSize: "18px !important",
												fontWeight: "500",
												background:
													"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
												marginTop: "25px",
												width: "100%",
											}}
											onClick={() => setAvatarPromptOpen(true)}
										>
											+ New Prompt
										</Box>
									</label>
								</Grid>
							</>
						) : activeMenu === "adminUsers" ? (
							<>
								<Grid
									item
									xs={2.5}
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
								>
									{!adminScreenShow ? (
										<Button
											sx={{
												borderRadius: "1.5rem",
												padding: "0 1.25rem",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												maxWidth: "14.65rem",
												height: "2.25rem",
												whiteSpace: "nowrap",
												backgroundColor: isDownloadComplete
													? "primary.main"
													: "#c0c0c0",
												color: isDownloadComplete ? "white" : "#808080",
												"&:disabled": {
													backgroundColor: "#e0e0e0",
													color: "#a0a0a0",
												},
											}}
											variant="contained"
											onClick={handleDownloadAllAdminData}
											disabled={!isDownloadComplete}
										>
											<Typography
												variant="body1"
												sx={{ color: isDownloadComplete ? "white" : "inherit" }} // Set the text color to white if download is complete
											>
												{isDownloadComplete
													? "Download All Admin Data"
													: "Downloading..."}
											</Typography>
											{!isDownloadComplete && (
												<Hourglass
													visible={true}
													height="1.25rem"
													width="1.25rem"
													ariaLabel="hourglass-loading"
													wrapperStyle={{ marginLeft: "0.5rem" }}
													colors={["#a0a0a0", "#d0d0d0"]}
												/>
											)}
										</Button>
									) : (
										""
									)}
								</Grid>
								<Grid
									item
									xs={2}
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
								>
									{!adminScreenShow ? (
										<Button
											variant="contained"
											onClick={handleClickOpen}
											sx={{ width: "165px", height: "36px" }}
											disabled={!isSuperAdmin}
										>
											<Typography variant="body1" className={classes.btnStyles}>
												Create Admin
											</Typography>
										</Button>
									) : (
										""
									)}
								</Grid>
							</>
						) : (
							""
						)}
					</Grid>

					<Grid container sx={{ paddingTop: "10px" }}>
						<Grid
							item
							xs={3}
							className={`${classes.shadowedBox} leftPanelWidth`}
							sx={{ padding: "1px", height: "115px" }}
						>
							<Box>
								<MenuList>
									{menus.map((menu, key) => {
										return (
											<>
												<MenuItem
													key={key}
													onClick={() => changeMenu(menu)} // onClick={() => changeMenu(menu)}
													sx={{
														padding: "10px 0 10px 16px",
														whiteSpace: "normal",
														overflowX: "auto",
														fontFamily: "Avenir",
														fontStyle: "normal",
														fontWeight: "600 !important",
														fontSize: "1.125rem",
														lineHeight: "25px",
														backgroundColor:
															activeMenu === menu.id ? "#F8F8F8" : "none",
														borderBottom:
															menu.title === "Avatar Prompt"
																? "1px solid #D7D7D7"
																: "0px solid #FFFFFF",
													}}
													disabled={menu.disabled}
												>
													<ListItemIcon>
														{activeMenu === menu.id ? (
															<img src={menu.activeIcon} alt="Account&Login" />
														) : (
															<img src={menu.icon} alt="Account&Login" />
														)}
													</ListItemIcon>
													<ListItemText
														primaryTypographyProps={{
															style: { fontSize: "1.125rem" },
														}}
													>
														{menu.title}
													</ListItemText>
													<ListItemIcon>
														{activeMenu === menu.id && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
												{/* <Divider variant="fullWidth" component="li" /> */}
											</>
										);
									})}
								</MenuList>
							</Box>

							<Box>
								<Grid container xs={12} className="center-align" my={4}>
									{/* <Grid item xs={8} >
										<Button variant="outlined" size="large" fullWidth onClick={() => navigate("/plans")} disabled>Buy Storage</Button>
									</Grid> */}
								</Grid>
							</Box>
						</Grid>

						<Grid item xs={9} className="centerPanelwoRight">
							<AdminDetails
								menus={menus}
								activeMenuTitle={activeMenuTitle}
								activeMenu={activeMenu}
								avatarPromptOpen={avatarPromptOpen}
								handleDialogClose={handleDialogClose}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<ActionDialog
				open={open || isEditing}
				handleClose={handleClose}
				title={isEditing ? "Edit Admin User" : "Create Admin User"}
				titleStyle={{
					color: "#5B5B65",
					fontSize: "1.5rem",
					fontWeight: "500",
				}}
				titleAlignment="left"
				actionButtons={
					<>
						<Button
							sx={{
								width: "12.5rem",
								height: "3rem",
								borderRadius: "24px",
							}}
							className="submit-button"
							size="large"
							variant="contained"
							disabled={
								!adminCreationInfo.firstName ||
								!adminCreationInfo.email ||
								!adminCreationInfo.adminRoleType ||
								isFetchingUserCreate ||
								emailError ||
								adminCreationInfo.firstName.length > 50
							}
							onClick={() => handleCreateUser(adminCreationInfo)}
						>
							{isEditing ? "Save" : "Create User"}
						</Button>
						<Button
							sx={{
								width: "12.5rem",
								height: "3rem",
								borderRadius: "24px",
							}}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							Cancel
						</Button>
					</>
				}
			>
				<FormHelperText sx={{ ml: 0 }}>
					<Typography variant="body4" color="customColor.label">
						Name
					</Typography>
				</FormHelperText>
				<TextField
					sx={{
						input: {
							color: "customColor.black",
							fontSize: theme.typography.subtitle1.fontSize,
							fontWeight: theme.typography.subtitle1.fontWeight,
							backgroundColor: "customColor.gray1",
							width: "20.5rem",
						},
					}}
					variant="outlined"
					value={adminCreationInfo?.firstName}
					onChange={(event) => {
						const value = event.target.value;
						if (/^[a-zA-Z ]*$/.test(value)) {
							setAdminCreationInfo((prevDetails) => {
								return {
									...prevDetails,
									firstName: event.target.value.trimStart(),
								};
							});
							setInvalidCharacterError(false);
						} else {
							setInvalidCharacterError(true);
						}
					}}
					error={
						invalidCharacterError || adminCreationInfo.firstName.length > 50
					}
					helperText={
						invalidCharacterError
							? "Allowed characters: A-Z a-z"
							: adminCreationInfo.firstName.length > 50
							? "Maximum 50 characters allowed"
							: ""
					}
				></TextField>
				<FormHelperText sx={{ ml: 0 }}>
					<Typography variant="body4" color="customColor.label">
						Email
					</Typography>
				</FormHelperText>
				<TextField
					sx={{
						input: {
							color: "customColor.black",
							fontSize: theme.typography.subtitle1.fontSize,
							fontWeight: theme.typography.subtitle1.fontWeight,
							backgroundColor: "customColor.gray1",
							width: "20.5rem",
						},
					}}
					onBlur={handleEmailBlur}
					variant="outlined"
					value={adminCreationInfo?.updatedEmail || adminCreationInfo?.email}
					onChange={(event) => {
						setEmailError(false);
						setAdminCreationInfo((prevDetails) => {
							return {
								...prevDetails,
								[isEditing ? "updatedEmail" : "email"]:
									event.target.value.trimStart(),
							};
						});
					}}
					error={emailError}
					helperText={emailError ? "Enter a valid email address" : ""}
				></TextField>

				<Typography
					variant="body1"
					component="p"
					sx={{
						fontSize: "1.125rem",
						fontWeight: "400",
						color: "#5B5B65",
						mt: 5,
						mb: 1,
					}}
				>
					Role Type
				</Typography>

				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					value={adminCreationInfo?.adminRoleType}
					onChange={(event) =>
						setAdminCreationInfo((prevDetails) => {
							return {
								...prevDetails,
								adminRoleType: event.target.value,
							};
						})
					}
					sx={{ mb: 10 }}
					name="radio-buttons-group"
				>
					<FormControlLabel
						value="ROLE_SUPER_ADMIN"
						control={<CircleRadio />}
						sx={{ mb: 2 }}
						label={
							<Stack>
								<HeadingTypo variant="body1" component="h6">
									Super User (owner)
								</HeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Has all access and can create other super users
								</SubHeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Only Super user can create other admins
								</SubHeadingTypo>
							</Stack>
						}
					/>
					<FormControlLabel
						value="ROLE_ADMIN"
						control={<CircleRadio />}
						sx={{ mb: 2 }}
						label={
							<Stack>
								<HeadingTypo variant="body1" component="h6">
									Admin User
								</HeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Can edit user profile
								</SubHeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Can moderate user feeds and comments
								</SubHeadingTypo>
							</Stack>
						}
					/>
					<FormControlLabel
						value="ROLE_ADMIN_CONTENT_MODERATOR"
						control={<CircleRadio />}
						sx={{ mb: 2 }}
						label={
							<Stack>
								<HeadingTypo variant="body1" component="h6">
									Admin Content Moderator
								</HeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Only view access to user profile
								</SubHeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Can moderate user feeds and comments
								</SubHeadingTypo>
							</Stack>
						}
					/>
					<FormControlLabel
						value="ROLE_ADMIN_VIEWER"
						control={<CircleRadio />}
						sx={{ mb: 2 }}
						label={
							<Stack>
								<HeadingTypo variant="body1" component="h6">
									Admin Viewer
								</HeadingTypo>
								<SubHeadingTypo variant="body2" component="p">
									Only view access to all user information
								</SubHeadingTypo>
							</Stack>
						}
					/>
				</RadioGroup>
			</ActionDialog>
		</>
	);
};

export default AdminSettings;
