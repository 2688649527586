import { useState, useEffect } from "react";
import {
	Card,
	CardContent,
	Typography,
	Input,
	CardMedia,
	CardHeader,
	IconButton,
	Dialog,
	DialogTitle,
	Box,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@mui/material";
import EditImage from "../../images/VectorEditIcon.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { SocialUSerDetails } from "../../types/types";
import DefaultImage from "../../images/banner-blankdefaultcoverphoto.jpg";
import Close from "../../images/deleteCover.svg";
import CloseIcon from "../../images/close.svg";
import ImageCropper from "../../pages/ImageCropper";
import { useUser } from "../../context/UserContext";
import ViewImage from "../commonComponents/ViewImage";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { Link, useNavigate } from "react-router-dom";

interface Props {
	userDetails: SocialUSerDetails;
}

const CoverPhoto = (props: Props) => {
	useEffect(() => {
		// getUserDetails();
	}, []);
	const selfDetails = useUser();
	const token = localStorage.getItem("sessionId");
	const [file, setFile] = useState<any>("");
	const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
	const [result, setResult] = useState("");
	const [open, setOpen] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const user = props?.userDetails?.privacySettings?.userProfile;
	const viewer = props.userDetails.loggedInUser?.ViewerStatus;
	const permissions = props.userDetails.privacySettings
		? props.userDetails.privacySettings
		: {};
	const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	const navigate = useNavigate();

	const handleClick = () => {
		setModalOpen(true);
	};

	const handleCloseDeleteDialog = () => {
		setOPenDeleteDialog(false);
	};

	const deleteUpload = () => {
		let formData = new FormData();
		formData.append("email", user?.user?.email ? user?.user?.email : "");
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/delete-cover-photo`,
			{
				method: "PUT",
				headers: {
					//   "Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					setImagePreviewUrl("");
					handleCloseDeleteDialog();
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.type.startsWith("image/")) {
			setResult(URL.createObjectURL(file));
			setFile(file);
			setOpen(true);
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	const photoUpload = (destFile: any) => {
		const reader = new FileReader();
		let formData = new FormData();
		formData.append("coverPhoto", destFile);
		formData.append("email", user?.user?.email ? user?.user?.email : "");
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-cover-photo`,
			{
				method: "PUT",
				headers: {
					// "Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					reader.readAsDataURL(destFile);
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};
	useEffect(() => {
		if (user?.coverPhotoUrl) {
			// setImagePreviewUrl(`data:image/jpeg;base64,${user?.coverPhoto}`);
			setImagePreviewUrl(
				`${process.env.REACT_APP_PICTURE_URL}${user?.coverPhotoUrl}`
			);
		}
		// else {
		//   setImagePreviewUrl(DefaultImage);
		// }
	}, [user]);

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const maxBioLength = 50;
	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>

			<Card
				sx={{
					minWidth: 300,
					flexGrow: 1,
					height: "300px",
					position: "relative",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "10px",
				}}
			>
				{selfDetails.userAccountStatus !== "SUSPEND" && viewer === "SELF" && (
					<>
						<label
							title="Upload Photo"
							style={{
								position: "absolute",
								top: "13px",
								right: "55px",
								cursor: "pointer",
								zIndex: 2,
							}}
						>
							<img
								id="photo-upload"
								style={{
									height: "32px",
									width: "32px",
									backgroundColor: "black",
								}}
								src={EditImage}
								alt="Upload"
							/>
							<input
								id="photo-upload"
								type="file"
								accept="image/*"
								style={{ display: "none" }}
								onChange={(e) => handleOpenEditor(e)}
							/>
						</label>
						{imagePreviewUrl && (
							<IconButton
								onClick={() => setOPenDeleteDialog(true)}
								sx={{
									position: "absolute",
									top: "5px",
									right: "5px",
									cursor: "pointer",
									zIndex: 2,
								}}
							>
								<img
									src={Close}
									alt="Close"
									style={{
										height: "32px",
										width: "32px",
										backgroundColor: "black",
									}}
								/>
							</IconButton>
						)}
					</>
				)}
				<CardMedia
					sx={{
						height: "300px",
						backgroundColor: "#727272",
						cursor: "pointer",
					}}
					image={
						(viewer !== "SELF" &&
							permissions?.viewProfilePermission ===
								"No one can view profile. Only me") ||
						(viewer === "NOT_IN_CONNECTION" &&
							permissions?.viewProfilePermission ===
								"Only my connections can view full profile")
							? ""
							: imagePreviewUrl
							? imagePreviewUrl
							: DefaultImage
					}
					// title="green iguana"
					onClick={handleClick}
				/>
				{viewer === "SELF" ? (
					<Typography
						variant="body1"
						sx={{
							position: "absolute",
							bottom: "7px",
							left: "12px",
							color: "white",
							zIndex: 1,
							fontSize: "36px",
							fontFamily: "Lexend",
						}}
					>
						{user?.profileTitle && user?.profileTitle?.length > maxBioLength
							? user?.profileTitle?.substring(0, maxBioLength) + "..."
							: user?.profileTitle}
					</Typography>
				) : (viewer !== "SELF" &&
						permissions?.viewProfilePermission ===
							"No one can view profile. Only me") ||
				  (viewer === "NOT_IN_CONNECTION" &&
						permissions?.viewProfilePermission ===
							"Only my connections can view full profile") ? (
					""
				) : (
					<Typography
						variant="body1"
						sx={{
							position: "absolute",
							bottom: "7px",
							left: "12px",
							color: "white",
							zIndex: 1,
							fontSize: "24px",
							fontFamily: "Lexexnd",
							fontWeight: "18px",
						}}
					>
						{user?.profileTitle && user?.profileTitle?.length > maxBioLength
							? user?.profileTitle?.substring(0, maxBioLength) + "..."
							: user?.profileTitle}
					</Typography>
				)}
			</Card>

			{isModalOpen && (
				<ViewImage
					open={isModalOpen}
					handleClose={() => setModalOpen(false)}
					ImageUrl={imagePreviewUrl ? imagePreviewUrl : DefaultImage}
					page="cover"
				/>
			)}

			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Picture</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => deleteUpload()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<ImageCropper
				open={open}
				onClose={handleClose}
				imagePreviewUrl={result}
				setImagePreviewUrl={setImagePreviewUrl}
				setResult={setFile}
				post={false}
				photoUpload={photoUpload}
				cover={true}
			/>
		</>
	);
};
export default CoverPhoto;
