import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Button,
    FormControl,
    Grid,
    Box,
    Typography,
    Card,
    CardHeader,
    CardContent,
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import ErrorMark from "../images/danger.svg";
import i18n from "../l10n/strings.json";

type LocationState = {
	errorKey: string;
    email: string;
};

const EmailNotExistError = () => {
    const location = useLocation();
	//const errorMessage = location.state?.errorMessage  || null;
    const errorKey = location.state as LocationState | undefined;
    const [cardContainerHeight, setCardContainerheight] = useState<number | undefined>(0);

    const getErrorMessage = (errorKey: string, email: string | undefined) => {
        switch (errorKey) {
          case 'AccountIsBanned':
            return `Your account (${email}) is banned.`;
          case 'emailNotexists':
            return i18n.login.accountdoesNotExist;
          case 'AccountIsTerminated':
            return `Your account (${email}) is terminated.`;
          case 'AccountIsSuspended':
            return `Your account (${email}) is suspended.`;
          default:
            return 'An error occurred. Please contact support for assistance.';
        }
      };

      const getSubMessage = (errorKey: string, email: string | undefined) => {
        switch (errorKey) {
          case 'AccountIsBanned':
            return (
                <>
                  Please contact support{' '}
                  <u>
                    <a href="mailto:support@Lutefish.com" style={{ color: '#FD4B24' }}>
                    support@Lutefish.com
                    </a>
                  </u>{' '}
                  or +1 34334343.
                </>
              );
          case 'emailNotexists':
            return i18n.login.emailCouldNotSent;
          case 'AccountIsTerminated':
            return (
                <>
                  Please contact support{' '}
                  <u>
                    <a href="mailto:support@Lutefish.com" style={{ color: '#FD4B24' }}>
                    support@Lutefish.com
                    </a>
                  </u>{' '}
                  or +1 34334343.
                </>
              );
            case 'AccountIsSuspended':
                return (
                    <>
                      Please contact support{' '}
                      <u>
                        <a href="mailto:support@Lutefish.com" style={{ color: '#FD4B24' }}>
                        support@Lutefish.com
                        </a>
                      </u>{' '}
                      or +1 34334343.
                    </>
                  );
          default:
            return 'An error occurred. Please contact support for assistance.';
        }
      };
      
    useEffect(() => {
        const element = document.getElementById("right-pannel-header");
        const rect = element?.getBoundingClientRect();
        setCardContainerheight(rect?.height);
    }, []);

    return (
        <Grid
            container={true}
            columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
        >
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <LeftPanel />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}  className="alignCenterTabLogin">
                <Grid container={true}
                    className="column"
                >
                    <Grid item={true} className="right-align"
                        id="right-pannel-header">
                        <Grid
                            container={true}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                            className="column right-align"
                            rowSpacing={{ xs: 1, sm: 3, md: 5, xl: 10, xxl: 20 }}
                        >
                            <Grid item={true} xs={12} className="right-align">
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Typography
                                        mr={2}
                                        mt={1}
                                        sx={{
                                            fontWeight: "500",
                                            fontSize: "1rem",
                                            lineHeight: "20px",
                                            color: "#5D5D65",
                                        }}
                                    >
                                        {i18n.signUp.notMemberMsg}
                                    </Typography>
                                    <Button
                                        component={Link}
                                        to="/signUpEmail"
                                        sx={{
                                            fontWeight: "900",
                                            fontSize: "1rem",
                                            marginRight: "5vw",
                                            backgroundColor: "#ffffff",
                                            borderRadius: "18px",
                                            color: "#0273E6",
                                            border: "1px solid #0273E6",
                                            height: "36px"
                                        }}
                                    >
                                        {i18n.signUp.title}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true}
                        xs={12}
                        className="center-align"
                    >
                        <Card
                            component="section"
                            sx={{
                                position: "relative",
                                top: `calc(50% - ${cardContainerHeight}px)`,
                                boxShadow: "none",
                                paddingBottom: 0,
                                marginTop: "150px",
                            }}
                        >
                            <CardContent className="center-align" sx={{ padding: 0 }}>
                                <FormControl sx={{ padding: 0, ml: errorKey?.errorKey === 'emailNotexists' ? 0 : 10 }}>
                                    <Grid
                                        container={true}
                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                        rowSpacing={{  sm: 0 }}
                                        className="column"
                                        sx={{mt:0}}
                                    >
                                        <Typography className="screen-title-custom-size"sx={{   }}>
                                        {/* {errorMessage?.errorMessage === 'Email not Exists!' ? i18n.login.accountdoesNotExist : errorMessage?.errorMessage || ''} */}
                                        {getErrorMessage(errorKey?.errorKey || '', errorKey?.email)}
                                        </Typography>
                                        <Grid item={true} className="row" sx={{ marginTop: "20px" }}>
                                            <Typography className="typography-field-custom-font-size"
                                                sx={{
                                                    textAlign: "start",
                                                    marginRight: "0.5rem"
                                                }}>
                                                <img src={ErrorMark} alt="error-mark" height="20px" />
                                            </Typography>
                                            <Typography className="typography-field-custom-font-size"
                                                sx={{
                                                    textAlign: "start", color: "#FD4B24", mt: -0.4
                                                }}
                                            >
                                                {/* {i18n.login.emailCouldNotSent} */}
                                                {getSubMessage(errorKey?.errorKey || '', errorKey?.email)}
                                            </Typography>
                                        </Grid>
                                        <Grid item={true} className="row">
                                            <Typography className="typography-field-custom-font-size"
                                                sx={{
                                                    textAlign: "start",
                                                }}>
                                                <a href="/#/signUpEmail"
                                                    style={{
                                                        color: "#0A6BFE",
                                                        marginRight: "0.5rem",
                                                    }}>
                                                    {i18n.signUp.title}
                                                </a>
                                            </Typography>
                                            <Typography className="typography-field-custom-font-size"
                                                sx={{
                                                    textAlign: "start",
                                                }}>
                                                {i18n.login.signUpForNewAccMsg}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}
export default EmailNotExistError;
