import {
	Autocomplete,
	Button,
	FormHelperText,
	Grid,
	Select,
	Slider,
	TextField,
	Typography,
	MenuItem
} from "@mui/material";
import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import i18n from "../../l10n/strings.json";
import { useEventsFilter } from "../Events/useEventsFilter";
import ErrorAlert from "../Error/ErrorAlert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import useLocationAutocomplete from "../../hooks/useLocationAutocomplete/useLocationAutocomplete";
import { checkIsValidDate } from "../../utils";
import { Constants } from "../../services/constants";
import GoogleLocationService from "../GoogleLocationService";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		backBtn: {
			width: "100%",
			fontWeight: "500 !important",
			color: "#0273E6 !important",
		},
		root: {
			color: "white",
			// '& .MuiAutocomplete-tag': {
			//     backgroundColor: 'blue',
			//     color: 'white'
			// },
			"& .MuiSvgIcon-root": {
				color: "white",
			},
			"& .MuiAutocomplete-endAdornment": {
				"& .MuiSvgIcon-root": {
					color: "#000 !important",
				},
			},
			"& .MuiChip-root.MuiChip-filled": {
				backgroundColor: "#0273E6",
				fontFamily: "Lexend",
				fontWeight: "400",
				color: "white",
				margin: "3px",
				"& svg.MuiChip-deleteIcon": {
					color: "white !important",
				},
				"& span.MuiAutocomplete-tag ": {
					color: "black !important",
					backgroundColor: "white !important",
				},
			},
		},
	})
);

type Genres = {
	// id: string;
	title: string;
};

type Instrument = {
	id: string;
	instrumentName: string;
	icon?: string;
};

const genreFilter: Array<Genres> = [
	{ title: "Americana" },
	{ title: "Avant-garde" },
	{ title: "Baroque" },
	{ title: "Big Band" },
	{ title: "Bluegrass" },
	{ title: "Blues" },
	{ title: "Bollywood" },
	{ title: "Broadway/Showtunes" },
	{ title: "Chamber-Music" },
	{ title: "Classic Rock" },
	{ title: "Classical" },
	{ title: "Contemporary Classical" },
	{ title: "Country" },
	{ title: "Disco" },
	{ title: "Drumline" },
	{ title: "EDM" },
	{ title: "Electronic/Dance" },
	{ title: "Folk" },
	{ title: "Funk" },
	{ title: "Gospel" },
	{ title: "Hip-hop/Rap" },
	{ title: "House" },
	{ title: "Indie/Alternative" },
	{ title: "Jazz" },
	{ title: "K-Pop" },
	{ title: "Latin" },
	{ title: "Metal" },
	{ title: "Minimalism" },
	{ title: "New Age" },
	{ title: "Opera" },
	{ title: "Orchestral" },
	{ title: "Pop" },
	{ title: "Punk" },
	{ title: "R&B" },
	{ title: "Ragtime" },
	{ title: "Reggae" },
	{ title: "Reggaeton" },
	{ title: "Religious" },
	{ title: "Rock" },
	{ title: "Romantic" },
	{ title: "Salsa" },
	{ title: "Ska" },
	{ title: "Soul" },
	{ title: "Soundtrack" },
	{ title: "Swing" },
	{ title: "Symphonic" },
	{ title: "Techno" },
	{ title: "Wind Ensemble" },
];

const marks = [
	{
		value: 0,
		label: 25,
	},
	{
		value: 25,
		label: 50,
	},
	{
		value: 50,
		label: 100,
	},
	{
		value: 75,
		label: 500,
	},
	{
		value: 100,
		label: "Any",
	},
];

function valuetext(value: number) {
	return `${value}`;
}

const GigsFilter = (props: any) => {
	const theme = useTheme();
	const classes = useStyles();
	const [genres, setGenres] = useState("");
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [instrumentList, setInstrumentList] = useState<Array<Instrument>>([]);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [inputValue, setInputValue] = useState("");
	const [searchString, setSearchString] = useState("");
	const optionsLocationAutoComplete = useLocationAutocomplete(searchString);
	const durationList = Constants.gigDuration;
	const [duration, setDuration] = useState<number>(0);
	const [city, setCity] = useState("");

	const {
		gigsFilter,
		setGigsFilter,
		isFilterActive,
		resetGigFilter,
		displayDistance,
		setDisplayDistance,
		setViewMoreGigs,
		setGigActiveMenu
	} = useEventsFilter();

	const clearFilter = () => {
		setDuration(0);
		resetGigFilter();
		setStartDate(null);
		setCity("");
		setViewMoreGigs(false)
		setGigActiveMenu("allGigs")
		setCity("")
	};

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					return { lat, lng };
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const updateSearchFilter = (field: string, value: any) => {


		switch (field) {
			case "gigTitle":
				setGigsFilter({
					...gigsFilter,
					title: value,
				});
				break;

			case "genres":
				var selectedGenres: any = [];
				value.map((genre: any) => {
					selectedGenres.push(genre.title);
				});
				setGigsFilter({
					...gigsFilter,
					genresArray: selectedGenres,
				});
				break;

			case "connectionDate":
				setGigsFilter({
					...gigsFilter,
					startDateTime: value,
				});
				break;

			case "location":
				setGigsFilter({
					...gigsFilter,
					cityCountry: value,
				});
				getLocationLatLng(value).then((result)=>{
					setGigsFilter((prevState: any) => {
						return {
							...prevState,
							latitude: result.lat,
					 		longitude: result.lng,
						}
					})
				})
				break;

			case "distanceFromCity":
				setGigsFilter({
					...gigsFilter,
					distanceFromCity: value,
				});
				break;

			case "instruments":
				var selectedInstrument: any = [];
				value.map((inst: any) => {
					selectedInstrument.push(inst.instrumentName);
				});

				selectedInstrument.sort();

				setGigsFilter({
					...gigsFilter,
					instrumentArray: selectedInstrument,
				});
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		getInstrumentsList();
	}, []);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setInstrumentList(data);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleSearchChange = (event: any) => {
		setSearchString(event.target.value);
	};

	return (
		<>
			<Grid
				className={`${classes.shadowedBox} fliter` }
				container
				xs={12}
				sx={{ height: "fit-content", pr: "20px", pl: "20px", pt: "12px" }}
			>
				<Grid item xs={12}>
					<Typography variant="h3" sx={{ textAlign: "center" }}>
						Filters
					</Typography>
				</Grid>
				{/* Gig title */}
				<Grid item xs={12}>
					<FormHelperText className="filterLabel">Gig Title</FormHelperText>
					<TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={gigsFilter.title}
						onChange={(event: any) =>
							updateSearchFilter("gigTitle", event.target.value)
						}
						error={gigsFilter.cityCountry.length > 64}
						size="small"
					></TextField>
					{/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
                    </FormHelperText> */}
				</Grid>

				{/* <Grid item xs={12}>
                    <FormHelperText className="filterLabel">
                        {i18n.usersmanagement.filters.genresTitle}
                    </FormHelperText>

                    <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={genreFilter}
                        getOptionLabel={(option) => option.title}
                        // getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
                        // defaultValue={}
                        value={genreFilter.filter((genre: any) => {
                            return gigsFilter.genres.includes(
                                genre.title as never
                            );
                        })}
                        renderInput={(params) => <TextField {...params} />}
                        sx={{ width: "100%" }}
                        disableClearable={false}
                        onChange={(event, newGenres) =>
                            updateSearchFilter("genres", newGenres)
                        }
                    />

                    {/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
                    </FormHelperText> */}
				{/* </Grid>  */}
				<Grid item xs={12}>
					<FormHelperText className="filterLabel">
						{i18n.usersmanagement.filters.instrumentsTitle}
					</FormHelperText>
					{/* <Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags1"
						options={instrumentList}
						getOptionLabel={(option) => option.instrumentName}
						classes={classes}
						// getOptionDisabled={(option) => !!selectedInstrumentList.find(element => element.id === option.id)}
						// defaultValue={}
						value={instrumentList.filter((ins: any) => {
							return gigsFilter.instrumentArray.includes(
								ins.instrumentName as never
							);
						})}
						renderInput={(params) => <TextField {...params} />}
						onChange={(event, newInstrument) =>
							updateSearchFilter("instruments", newInstrument)
						}
					/> */}
					<Autocomplete
						multiple
						limitTags={1}
						id="instruments"
						classes={classes}
						className="social-textField"
						value={instrumentList.filter((ins: any) => {
							return gigsFilter.instrumentArray.includes(
								ins.instrumentName as never
							);
						})}
						onChange={(event, newInstrument) =>
							updateSearchFilter("instruments", newInstrument)
						}
						options={instrumentList.sort((a: any, b: any) =>
							a.instrumentName.localeCompare(b.instrumentName)
						)}
						getOptionLabel={(option) => option.instrumentName || ""}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						// filterSelectedOptions
						renderInput={(params) => <TextField {...params} />}
					/>
				</Grid>

				{/* Date Field */}
				<Grid item xs={12}>
					<FormHelperText sx={{ ml: 0 }} className="filterLabel">
						<Typography variant="body4" color="customColor.label">
							Gig Duration
						</Typography>
					</FormHelperText>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							openTo="day"
							views={["year", "month", "day"]}
							inputFormat="MM/dd/yyyy"
							value={startDate}
							onChange={(newValue: Date | null) => {
								if (newValue) {
									// Convert the selected date to UTC
									const utcDate = new Date(
										Date.UTC(
											newValue.getFullYear(),
											newValue.getMonth(),
											newValue.getDate()
										)
									);

									setStartDate(utcDate);
									updateSearchFilter("connectionDate", utcDate);
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									onKeyDown={(e) => e.preventDefault()}
									sx={{
										"&.MuiTextField-root": {
											backgroundColor: "customColor.gray1",
											width: "100%",
										},
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
										},
									}}
								/>
							)}
						/>
					</LocalizationProvider> */}
					
					<Select
											labelId="demo-simple-select-label"
											value={duration}
											onChange={(event: any) => {
												setDuration(event.target.value);
												updateSearchFilter("connectionDate", event.target.value);
											}}
											sx={{
												backgroundColor: "customColor.gray1",
												color: duration === 0 ? "gray" : "customColor.black",
												width: "100%",
											}}
										>
											{durationList.map((duration) => (
											<MenuItem key={duration.value} value={duration.value}>
													{duration.label}
												</MenuItem>
											))}
										</Select>
										
				</Grid>

				{/* City Field */}
				<Grid item xs={12}>
					<FormHelperText className="filterLabel">
						{i18n.usersmanagement.filters.cityTitle}
					</FormHelperText>
					<GoogleLocationService
						defaultValue={city}
						onLocationChange={(locationDesc: string) => {
							setCity(locationDesc);
							updateSearchFilter("location", locationDesc);
						}}
					/>
					{/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
                    </FormHelperText> */}
				</Grid>

				{/* Distance from City Field */}
				<Grid item xs={12} mr={1.2} mb={2}>
					<FormHelperText className="filterLabel">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>{i18n.events.filters.distanceTitle}</div>
							<div>Miles</div>
						</div>
					</FormHelperText>
					{/* <Slider
                        defaultValue={50}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        onChange={(event: any, distance: any) => {
                            // setDistanceFromCity(distance)
                            updateSearchFilter("distanceFromCity", distance);
                        }}
                    /> */}

					<Slider
						defaultValue={25}
						disabled={
							gigsFilter.cityCountry == null ||
							gigsFilter.cityCountry.length === 0
						}
						value={displayDistance}
						step={null}
						marks={marks}
						onChange={(event, value) => {
							// Handle the value change
							let updatedValue: any;
							marks.map((mark) => {
								if (mark.value === value) {
									if (mark.value === 100) {
										updatedValue = 100000;
										setDisplayDistance(100);
									} else {
										updatedValue = mark.label;
										setDisplayDistance(mark.value);
									}
									updateSearchFilter("distanceFromCity", +updatedValue);
								}
							});
						}}
						valueLabelFormat={valuetext}
					/>
				</Grid>

				{isFilterActive && (
					<Grid container xs={12} justifyContent="center" marginY={2}>
						<Button
							variant="text"
							sx={{ border: "2px solid #0273E6", color: "#0273E6" }}
							size="small"
							onClick={() => {
								props.fetchData();
								clearFilter();
							}}
						>
							<Typography variant="h6">Clear Filter</Typography>
						</Button>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default GigsFilter;
