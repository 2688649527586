import React, {
	createContext,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { Client } from "@twilio/conversations";
import { Conversation } from "@twilio/conversations/";
import { Message } from "@twilio/conversations/";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { SessionInterface } from "../../types/types";
import { Link, useNavigate } from "react-router-dom";

type ChatContextType = {
	isChatWindowOpen: boolean;
	setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
	connect: (token: string, session: SessionInterface) => void;
	hasUnreadMessages: boolean;
	messages: Message[];
	conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider = ({ children }: any) => {
	const { room, onError } = useVideoContext();
	const isChatWindowOpenRef = useRef(false);
	const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
	const [conversation, setConversation] = useState<Conversation | null>(null);
	const [messages, setMessages] = useState<Message[]>([]);
	const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
	const [chatClient, setChatClient] = useState<Client>();
	const navigate = useNavigate();
	const [currJamSession, setCurrJamSession] = useState<SessionInterface | null>(
		null
	);

	const token = localStorage.getItem("sessionId");
	const connect = useCallback(
		(token: string, session: SessionInterface) => {
			const client = new Client(token);
			setCurrJamSession(session);
			const handleClientInitialized = (state: string) => {
				if (state === "initialized") {
					// @ts-ignore
					window.chatClient = client;
					setChatClient(client);
				} else if (state === "failed") {
					onError(
						new Error(
							"There was a problem connecting to Twilio's conversation service."
						)
					);
				}
			};

			client.on("stateChanged", handleClientInitialized);

			return () => {
				client.off("stateChanged", handleClientInitialized);
			};
		},
		[onError]
	);

	useEffect(() => {
		if (conversation) {
			const handleMessageAdded = (message: Message) =>
				setMessages((oldMessages) => [...oldMessages, message]);
			conversation
				.getMessages()
				.then((newMessages) => setMessages(newMessages.items));
			conversation.on("messageAdded", handleMessageAdded);
			return () => {
				conversation.off("messageAdded", handleMessageAdded);
			};
		}
	}, [conversation]);

	useEffect(() => {
		// If the chat window is closed and there are new messages, set hasUnreadMessages to true
		if (!isChatWindowOpenRef.current && messages.length) {
			setHasUnreadMessages(true);
		}
	}, [messages]);

	useEffect(() => {
		isChatWindowOpenRef.current = isChatWindowOpen;
		if (isChatWindowOpen) setHasUnreadMessages(false);
	}, [isChatWindowOpen]);

	useEffect(() => {
		const fetchConversation = () => {
			setTimeout(() => {
				fetch(
					`${process.env.REACT_APP_BASE_URL}/api/sessions/${currJamSession?.id}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				)
					.then((response) => {
						if (response.status >= 400) {
							throw new Error();
						} else {
							return response.json();
						}
					})
					.then((data) => {
						if (data.conversationId !== null) {
							chatClient
								?.peekConversationBySid(data.conversationId)
								.then((newConversation: any) => {
									//@ts-ignore
									window.chatConversation = newConversation;
									newConversation.add(room!.localParticipant.identity);
									setConversation(newConversation);
								})
								.catch((e) => {
									fetchConversation();
									console.error(e);
								});
						} else {
							fetchConversation();
						}
					});
			}, 1000);
		};

		if (room?.sid && chatClient && currJamSession) {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/sessions/${currJamSession.id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((response) => {
					if(response.status === 502){
						navigate('/defaultPage')
					} else if (response.status >= 400) {
						throw new Error();
					} else {
						return response.json();
					}
				})
				.then((data) => {
					if (data.conversationId !== null) {
						console.info("conversation sid : ", data.conversationId);
						chatClient
							.peekConversationBySid(data.conversationId)
							.then((newConversation) => {
								//@ts-ignore
								window.chatConversation = newConversation;
								newConversation.add(room!.localParticipant.identity);
								setConversation(newConversation);
							})
							.catch((e) => {
								console.error(e);
							});
					} else {
						chatClient
							.createConversation({ uniqueName: room.name, friendlyName: currJamSession ? 'JAM@@@' + currJamSession.sessionName : 'JAM@@@' + room.name, })
							.then((newConversation) => {
								//@ts-ignore
								window.chatConversation = newConversation;
								if (newConversation) {
									fetch(
										`${process.env.REACT_APP_BASE_URL}/api/sessions/${currJamSession.id}`,
										{
											method: "PUT",
											headers: {
												"Content-Type": "application/json",
												Authorization: `Bearer ${token}`,
											},
											body: JSON.stringify({
												...currJamSession,
												conversationId: newConversation.sid,
											}),
										}
									)
										.then((response) => {
											if (response.status >= 400) {
												throw new Error();
											} else {
												return response.json();
											}
										})
										.then((data) => {
											newConversation.add(room!.localParticipant.identity);
											setConversation(newConversation);
										});
								}
							})
							.catch((e) => {
								fetchConversation();
							});
					}
				});

			// chatClient
			// 	.getConversationByUniqueName(room.sid)
			// 	.then((newConversation) => {
			// 		//@ts-ignore
			// 		window.chatConversation = newConversation;
			// 		setConversation(newConversation);
			// 	})
			// 	.catch((e) => {
			// 		console.error(e);
			// 		onError(
			// 			new Error(
			// 				"There was a problem getting the Conversation associated with this room."
			// 			)
			// 		);
			// 	});
		}
	}, [room, chatClient, onError]);

	return (
		<ChatContext.Provider
			value={{
				isChatWindowOpen,
				setIsChatWindowOpen,
				connect,
				hasUnreadMessages,
				messages,
				conversation,
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};
