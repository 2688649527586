import {
	HashRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import RootRenderElement from "./RootRenderElement";
import Home from "./components/Home";
import user from "./services/user";
import AddDeviceSetting from "./pages/AddDeviceSetting";
import AddAdminDeviceSetting from "./pages/AddAdminDeviceSetting";
import DeviceSetting from "./pages/DeviceSetting";
import AdminDeviceSetting from "./pages/AdminDeviceSetting";
import { AdminNotificationSettings } from "./pages/AdminNotificationSettings";
import SettingsContainer from "./pages/SettingsContainer";
//import PreferencesSettingsPage from "./pages/PreferencesSettingsPage";
import AccountSettingsPage from "./pages/AccountSettingsPage";
import AdminAccountSettingsPage from "./pages/AdminAccountSettingsPage";
import AdminPrivacySetting from "./pages/AdminPrivacySetting";
import AdminSecurity from "./pages/AdminSecurity";

import SecuritySettingsPage from "./pages/SecuritySettingsPage";
import Container from "./Container";
import Login from "./components/Login";
import SignUp from "./components/Sign-up";
import { UserProvider } from "./context/UserContext";
import { AlertStatusProvider } from "./context/AlertStatusContext";
import theme from "./theme";
import JamSession from "./components/jam-session/JamSession";
import SignUpEmail from "./components/SignUpEmail";
import CodeVerification from "./components/CodeVerification";
import AccountLocked from "./components/AccountLocked";
import ForgotPassword from "./components/ForgotPassword";
import AccountRegistered from "./components/AccountRegistered";
import ResetPassword from "./components/ResetPassword";
import EmailNotExistError from "./components/EmailNotExistError";
import VideoSession from "./components/session/VideoSession";
import MyMusic from "./components/MyMusic/MyMusic";

import AppStateProvider from "./state";
import SocialUp from "./components/Social/socialUp";
import SocialProfile from "./components/Social/socialProfile";
import SocialHome from "./components/Social/socialHome";
import PrivacySetting from "./pages/PrivacySetting";
import SocialProfileSetting from "./pages/SocialProfileSetting";

import LiveStream from "./components/SessionLiveStream/LiveStream";
import { Notifications } from "./components/Notifications/Notifications";
import Musicians from "./components/Musicians/Musicians";
import { RateJamSession } from "./components/RateJamSession/RateJamSession";
import { SocialUserProvider } from "./context/SocialSignupUser";
import CompatibilityTestScreen from "./pages/CompatibilityTestScreen";
import MusiciansFilterContainer from "./components/Musicians/MusiciansFilterContainer";
import Mutuals from "./components/Musicians/Mutuals";
import RecentJoiners from "./components/Musicians/RecentJoiners";
import ActiveMusicians from "./components/Musicians/ActiveMusicians";
import RecomendedMusicians from "./components/Musicians/RecomendedMusicians";
import MusicianSearchResult from "./components/Musicians/MusicianSearchResult";
import { BlockedUserList } from "./pages/BlockedUserList";
import { NotificationSettings } from "./pages/NotificationSettings";
import Invitations from "./components/Musicians/Invitations";
import MyConnections from "./components/Musicians/MyConnections";
import Shop from "./components/Subscription/Shop";
import SubscriptionPayments from "./components/Subscription/SubscriptionPayments";
import SubscriptionPricing from "./components/Subscription/SubscriptionPlans";
import UsersManagement from "./components/AdminDashboard/ManageUserDashBoard/UsersManagement";
import SinglePostView from "./components/Posts/SinglePostView";
import { UserManagementContainer } from "./components/AdminDashboard/ManageUserDashBoard/UserManagementContainer";
import { DeviceManagementContainer } from "./components/AdminDashboard/ManageUserDashBoard/DeviceManagementContainer";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import ModerationManagement from "./components/AdminDashboard/Moderation/ModerationManagement";
import ContentModerationTable from "./components/AdminDashboard/Moderation/ContentModerationTable";
import UsersModerationTable from "./components/AdminDashboard/Moderation/UsersModerationTable";
import MessagingContainer from "./components/Messages/MessagingContainer";
import Events from "./components/Events/Events";
import PrivateRoute from "./PrivateRoute";
import { resolve } from "path";
import LinkPostPreview from "./pages/LinkPostPreview";
import ViewEvent from "./components/Events/ViewEvent";
import Gigs from "./components/Gigs/Gigs";
import ViewGig from "./components/Gigs/ViewGig";
import ViewMoreEvents from "./components/Events/ViewMoreEvents";
import { ReferralCodesManagementContainer } from "./components/AdminDashboard/ManageUserDashBoard/SocialsiteCode/ReferralCodes/ReferralCodesManagementContainer";
import { WaitlistedCodesManagementContainer } from "./components/AdminDashboard/ManageUserDashBoard/SocialsiteCode/WaitlistedCodes/WaitlistedCodesManagementContainer";
import JoinWaitlistSuccess from "./components/JoinWaitlistSuccess";
import { GenerateAvatar } from "./components/commonComponents/GenerateAvatar";
import { SignUpProvider } from "./context/SignUpContext";
import HelpAndSupport from "./components/HelpAndSupport/HelpAndSupport";
import GigsAndColab from "./pages/GigsAndColab";
import AdminSettings from "./components/AdminDashboard/AdminSettings/AdminSettings";
import ViewGoogleEvent from "./components/Events/ViewGoogleEvent";
import SignupMailSent from "./components/newSignupFlow/SignupMailSent";
import SocialProfileNew from "./components/newSignupFlow/SocialProfileNew";
import AdminSettingsMenu from "./pages/AdminSettingsContainer";
import DefaultPage from "./components/DefaultPage";
import UserTab from "./components/AdminDashboard/ManageUserDashBoard/UserTab";
import AccountActions from "./components/AdminDashboard/AdminSettings/AdminActions";
import SubscriptionSetting from "./components/AdminDashboard/AdminSettings/SubscriptionSetting";
import AdminDataExport from "./pages/AdminDataExport";
import GoogleAds from "./components/googleads/GoogleAds";
import DeviceManagement from "./components/AdminDashboard/AdminDeviceManagement";
import NetworkStatus from "./components/NetworkStatus/NetworkStatus";

function IndexRouter() {
	return (
		<ThemeProvider theme={theme}>
			<SocialUserProvider>
				<NetworkStatus/>
				<UserProvider>
					<AlertStatusProvider>
						<AppStateProvider>
							<SignUpProvider>
								<Router>
									<Routes>
										<Route path="/" element={<RootRenderElement />} />
										<Route path="/*">
											<Route path="jamsessionlink" element={<RootRenderElement />} />
											<Route path="adsTest" element={<GoogleAds />} />
											<Route path="postView" element={<RootRenderElement />} />
											<Route
												path="viewsocialprofile"
												element={<RootRenderElement />}
											/>
											{/* <Route
												path="technicalSupport"
												element={<RootRenderElement />}
											/> */}
											<Route
												path="viewMessages"
												element={<RootRenderElement />}
											/>
											<Route
												path="link-preview"
												element={<LinkPostPreview />}
											/>
											<Route path="defaultPage" element={<DefaultPage />} />
											<Route path="login" element={<Login />} />
											<Route path="sign-up" element={<SignUp />} />
											<Route path="socialUp" element={<SocialUp />} />
											<Route path="signUpEmail" element={<SignUpEmail />} />

											{/* fresh signup route */}
											<Route
												path="signup-verify"
												element={<SignupMailSent />}
											/>

											<Route path="codeVerify" element={<CodeVerification />} />
											<Route path="accountLocked" element={<AccountLocked />} />
											<Route path="forgotPassword" element={<ForgotPassword />} />
											<Route path="socialProfile" element={<SocialProfile />} />
											{/* fresh signup route */}
											<Route
												path="socialProfileNew"
												element={<SocialProfileNew />}
											/>
											<Route
												path="generateAvatar"
												element={<GenerateAvatar />}
											/>
											<Route
												path="joinWaitlistSuccess"
												element={<JoinWaitlistSuccess />}
											/>
											<Route
												path="compatibilityTest"
												element={<CompatibilityTestScreen />}
											/>

											<Route path="live/*" element={<LiveStream />} />
											<Route path="accountRegistered" element={<AccountRegistered />} />
											<Route path="emailNotExistError" element={<EmailNotExistError />} />
											<Route path="resetPassword" element={<ResetPassword />} />
											<Route path="home" element={<Container />}>
												<Route index={true} element={<Home />} />
												<Route
													path="settings/:menuId"
													element={<SettingsContainer />}
												>
													{/* Hiding for Social-Launch */}
													<Route path="add" element={<AddDeviceSetting />} />
													<Route path="device" element={<DeviceSetting />} />
													<Route
														path="account"
														element={<AccountSettingsPage />}
													/>
													<Route
														path="security"
														element={<SecuritySettingsPage />}
													/>
													<Route path="privacy" element={<PrivacySetting />} />
													<Route
														path="socialProfileSetting"
														element={<SocialProfileSetting />}
													/>
													<Route
														path="blockedUsers"
														element={<BlockedUserList />}
													/>
													<Route
														path="notificationSettings"
														element={<NotificationSettings />}
													/>
													<Route
														path="subscriptionPayment"
														element={<SubscriptionPricing />}
													/>
													<Route path="plans" element={<SubscriptionPricing />} />
													<Route path="gigs" element={<GigsAndColab />} />
													{/* <Route path="preferences" element={<PreferencesSettingsPage />}/> */}
												</Route>

												<Route
													path="admin/settings/:menuId"
													element={<AdminSettingsMenu />}
												>
													<Route path="add" element={<AddDeviceSetting />} />
													<Route path="device" element={<DeviceSetting />} />
													<Route path="admindevice" element={<AdminDeviceSetting />} />
													<Route path="addadmindevice" element={<AddAdminDeviceSetting />} />
													<Route path="notificationSettings" element={<AdminNotificationSettings />} />
													<Route path="account" element={<AdminAccountSettingsPage />}
													/>
													<Route path="privacy" element={<AdminPrivacySetting />} />
													<Route path="security" element={<AdminSecurity />} />
													<Route path="dataexport" element={<AdminDataExport />} />
													{/* <Route path="subscription" element={<SubscriptionSetting />}
													/> */}
													<Route
														path="accountactions"
														element={<AccountActions />}
													/>
												</Route>
											</Route>
											<Route path="jamsession" element={<Container />}>
												<Route index={true} element={<JamSession />} />
											</Route>
											<Route path="notifications" element={<Container />}>
												<Route index={true} element={<Notifications />} />
											</Route>
											<Route path="mymusic" element={<Container />}>
												<Route index={true} element={<MyMusic />} />
											</Route>
											<Route path="session" element={<Container />}>
												<Route index={true} element={<VideoSession />} />
											</Route>
											<Route
												path="socialHome/:nickName"
												element={<Container />}
											>
												<Route index={true} element={<SocialHome />} />
											</Route>
											<Route
												path="userProfile/:nickName"
												element={<Container />}
											>
												<Route index={true} element={<SocialHome />} />
											</Route>
											<Route path="ratejamsession" element={<Container />}>
												<Route index={true} element={<RateJamSession />} />
											</Route>
											<Route path="singlePostView" element={<Container />}>
												<Route index={true} element={<SinglePostView />} />
											</Route>
											<Route path="musicians" element={<Container />}>
												<Route index={true} element={<Musicians />} />

												<Route element={<Musicians />}>
													<Route path="invitations" element={<Invitations />} />
													<Route
														path="connections"
														element={<MyConnections />}
													/>
												</Route>
												<Route
													// path="/"
													element={<MusiciansFilterContainer />}
												>
													<Route path="mutuals/:userId" element={<Mutuals />} />
													<Route path="recent" element={<RecentJoiners />} />
													<Route path="active" element={<ActiveMusicians />} />
													<Route
														path="recomended"
														element={<RecomendedMusicians />}
													/>
													<Route
														path="search"
														element={<MusicianSearchResult />}
													/>
												</Route>
											</Route>

											<Route path="shop" element={<Container />}>
												<Route index={true} element={<Shop />} />
											</Route>

											<Route path="plans" element={<Container />}>
												<Route index={true} element={<SubscriptionPricing />} />
											</Route>

											<Route path="messages" element={<Container />}>
												<Route index={true} element={<MessagingContainer />} />
											</Route>

											{/* <Route path="plans" element={<Container />}>
												<Route index={true} element={<SubscriptionPricing />} />
											</Route> */}

											<Route path="helpandsupport" element={<Container />}>
												<Route index={true} element={<HelpAndSupport />} />
											</Route>

											{/* <Route path="users-management" element={<Container />}>
											<Route
												element={
													<PrivateRoute
														element={<UsersManagement />}
														allowedRoles="ROLE_ADMIN"
														userRole={getUserRole()}
													/>
												}
											>
												<Route
													index={true}
													element={<UserManagementContainer />}
												/>
												<Route
													path="device"
													element={<DeviceManagementContainer />}
												/>
												<Route
													index={true}
													path=":userId"
													element={<UserManagementContainer />}
												/>
											</Route>
										</Route> */}
											<Route path="events" element={<Container />}>
												<Route index={true} element={<Events />} />
												<Route
													path="eventDetails/:eventId"
													element={<ViewEvent />}
												/>
												<Route
													path="googleEventDetails"
													element={<ViewGoogleEvent />}
												/>
												<Route
													path="viewMoreEvents"
													element={<ViewMoreEvents />}
												/>
											</Route>
											<Route path="gigs" element={<Container />}>
												<Route index={true} element={<Gigs />} />
												<Route path="gigDetails/:gigId" element={<ViewGig />} />
											</Route>
											<Route
												path="*"
												element={<Navigate to="/" replace={true} />}
											/>
										</Route>

										<Route path="admin-dashboard" element={<Container />}>
											<Route
												index={true}
												element={
													<PrivateRoute
														element={<AdminDashboard />}
														allowedRoles={[
															"ROLE_ADMIN",
															"ROLE_SUPER_ADMIN",
															"ROLE_ADMIN_CONTENT_MODERATOR",
															"ROLE_ADMIN_VIEWER",
														]}
													/>
												}
											/>
										</Route>
										<Route path="moderation-management" element={<Container />}>
											<Route
												element={
													<PrivateRoute
														element={<ModerationManagement />}
														allowedRoles={[
															"ROLE_ADMIN",
															"ROLE_SUPER_ADMIN",
															"ROLE_ADMIN_CONTENT_MODERATOR",
															"ROLE_ADMIN_VIEWER",
														]}
													/>
												}
											>
												<Route
													index={true}
													element={<ContentModerationTable />}
												/>
												<Route
													path="users"
													element={<UsersModerationTable />}
												/>
											</Route>
										</Route>
										<Route path="users-management" element={<Container />}>
											<Route
												element={
													<PrivateRoute
														element={<UserTab />}
														allowedRoles={[
															"ROLE_ADMIN",
															"ROLE_SUPER_ADMIN",
															"ROLE_ADMIN_CONTENT_MODERATOR",
															"ROLE_ADMIN_VIEWER",
														]}
													/>
												}
											>
												<Route
													index={true}
													element={<UserManagementContainer />}
												/>
												<Route
													path="device"
													element={<DeviceManagementContainer />}
												/>
												<Route
													path=":userId"
													element={<UserManagementContainer />}
												/>
												{/* <Route
													path="registereduser"
													element={<UserManagementContainer />}
												/>
												<Route
													path="waitlistedusers"
													element={<WaitlistedCodesManagementContainer />}
												/> */}
											</Route>
										</Route>
										<Route path="device-inventory" element={<Container />}>
											<Route
												index={true}
												element={
													<PrivateRoute
														element={<DeviceManagement />}
														allowedRoles={[
															"ROLE_ADMIN",
															"ROLE_SUPER_ADMIN",
															"ROLE_ADMIN_CONTENT_MODERATOR",
															"ROLE_ADMIN_VIEWER"
														]}
													/>
												}
											></Route>
										</Route>
										<Route path="admin-settings" element={<Container />}>
											<Route
												index={true}
												element={
													<PrivateRoute
														element={<AdminSettings />}
														allowedRoles={[
															"ROLE_ADMIN",
															"ROLE_SUPER_ADMIN",
															"ROLE_ADMIN_CONTENT_MODERATOR",
															"ROLE_ADMIN_VIEWER",
														]}
													/>
												}
											></Route>
										</Route>
									</Routes>
								</Router>
							</SignUpProvider>
						</AppStateProvider>
					</AlertStatusProvider>
				</UserProvider>
			</SocialUserProvider>
		</ThemeProvider>
	);
}

export default IndexRouter;
