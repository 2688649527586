import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, IconButton, Paper, Theme, Typography } from "@mui/material";
import Close from "../../images/close-highlighted.svg";
import { useNavigate } from "react-router-dom";
import { clearLocalstorage } from "../../utils";
import InstrumentSkillLevel from "../Musicians/InstrumentSkills";
import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: "100vw",
		color: "#fff",
		backgroundColor: "#000",
		height: "calc(100vh - 74px)",
		position: "relative",
	},
	paper: {
		textAlign: "center",
		padding: theme.spacing(8),
		background: "#454545 !important",
	},
	containedButton: {
		backgroundColor: "#FF702A !important",
		borderRadius: "20px !important",
	},
	outlinedButton: {
		backgroundColor: "transparent !important",
		border: "2px solid #FF702A !important",
		color: "#FF702A !important",
		borderRadius: "20px !important",
	},
	ratingCard: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
}));
export const RateJamSession = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [rating, setRating] = useState(0);
	const [session, setSession] = useState<Partial<{ id: string, twilioSessionId: string }>>({});
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	const onRejoinSession = () => {
		if (session && session.hasOwnProperty('id')) {
			const sessionId = session.id;
			navigate(`/jamsessionlink?sid=${sessionId}`);
		}
	}
	const handleSubmitRating = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-rating`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userId: user.id,
				sessionId: session.twilioSessionId,
				rating: rating
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
			});
		clearLocalstorage();
		navigate("/jamsession");
	}

	useEffect(() => {
		const sessionStr = localStorage.getItem("userSession");
		if (sessionStr) { 
			const session = JSON.parse(sessionStr);
			setSession(session);
		}
	}, []);
	return (
		<div className={classes.container}>
			<Box sx={{ marginTop: 2 }}>
				<Grid container textAlign="center">
					<Grid item xs={12}>
						<Typography variant="body1" style={{ color: "#fff" }} gutterBottom>
							You have left the jam session or it may be over
						</Typography>
						<Button variant="outlined" onClick={onRejoinSession} className={classes.outlinedButton}>
							Rejoin Session
						</Button>
						<Box sx={{ position: "absolute", top: "5%", right: "7%" }}>
							<IconButton
								onClick={() => {
									clearLocalstorage();
									navigate("/jamsession");
								}}
								>
									<img src={Close} alt="Smileys" />
								</IconButton>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box className={classes.ratingCard}>
				<Grid container>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography
								variant="h5"
								sx={{ fontWeight: "900", color: "#fff" }}
							>
								Please rate this Jam Session
							</Typography>
							<Box sx={{ textAlign: "center", marginTop: 3 }}>
								<InstrumentSkillLevel background={'transparent'} center setRating={setRating} editable={true} source="ratejamsession" />
							</Box>
							<Button
								variant="contained"
								sx={{ marginTop: 3 }}
								className={classes.containedButton}
								onClick={() => handleSubmitRating()}
							>
								Submit Rating
							</Button>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};
