import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import Search from "../../../images/search.svg";
import CloseIcon from "../../../images/close.svg";
import { visuallyHidden } from "@mui/utils";
import {
	FormControl,
	Grid,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { useEffect, useState, useRef, KeyboardEvent } from "react";
import UserView from "./UserView";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useUserModerationFilter } from "./ModerationManagementProvider/useUserModerationFilter";
import { useContentModerationFilter } from "./ContentModerationManagementProvider/useContentModerationFilter";
import ErrorAlert from "../../Error/ErrorAlert";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		recordingBtn: {
			width: "100%",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			// border: "1px solid #C5C5C5",
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			// fontFamily: 'Lexend !important',
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

interface Data {
	userName: string;
	reportDateTime: string;
	reportType: string;
	count: string;
	status: string;
	actions: string;
}

function createData(
	userName: string,
	reportDateTime: string,
	reportType: string,
	count: string,
	status: string,
	actions: string
): Data {
	return {
		userName,
		reportDateTime,
		reportType,
		count,
		status,
		actions,
	};
}

type Order = "asc" | "desc";

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
	array: readonly T[],
	comparator: (a: T, b: T) => number
) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
	width: string;
}

const headCells: readonly HeadCell[] = [
	{
		id: "userName",
		numeric: false,
		disablePadding: true,
		label: "Name",
		//width: "39%",
		width: "36%"
	},
	{
		id: "reportDateTime",
		numeric: true,
		disablePadding: true,
		label: "Report Date & Time",
		width: "25%",
	},
	{
		id: "count",
		numeric: true,
		disablePadding: true,
		label: "User Count",
		//width: "18%",
		width: "15%"
	},
	{
		id: "status",
		numeric: true,
		disablePadding: true,
		label: "Status",
		//width: "10%",
		width: "13%"
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: true,
		label: "Actions",
		//width: "8%",
		width: "11%"
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		onSelectAllClick,
		// ,
		orderBy,
		numSelected,
		rowCount,
	} = props;

	const [order, setOrder] = React.useState<Order>("asc");

	const { setOrderByField, handleUsersReset, orderByField } =
		useUserModerationFilter();

	const handleSorting = (field: any) => {
		setOrderByField((prevValue: any) => {
			const newOrderByField = orderByField === field ? `${field}Desc` : field;
			return newOrderByField;
		});
	};

	useEffect(() => {
		handleUsersReset();
	}, [orderByField]);

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" sx={{bgcolor:'#F5F5F5',borderRight: '1px solid #C2C2C3 !important',}}>
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < Math.min(rowCount, 10)}
						checked={rowCount > 0 && numSelected === Math.min(rowCount, 10)}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{headCells.map((headCell:any, index:number) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						width={headCell.width}
						sx={{ fontSize: "16px", fontWeight: 500, fontFamily: "Lexend",bgcolor:'#F5F5F5', borderRight: index < headCells.length - 1 ? '1px solid #C2C2C3 !important' : 'none',paddingLeft: '8px !important'
					}}
					>
						{headCell.id !== "actions" ? (
							<TableSortLabel
								active={orderByField === headCell.id}
								direction={orderByField === headCell.id ? "asc" : "desc"}
								onClick={() => handleSorting(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: "16px !important",
									color: "#000",
									fontFamily: "Lexend",
								}}
								color="textPrimary"
							>
								{headCell.label}
							</Typography>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const token = localStorage.getItem("sessionId");
	const { numSelected } = props;
	const classes = useStyles();
	const [selectedFolder, setSelectedFolder] = React.useState("");
	const [userStatus, setUserStatus] = React.useState("");
	const [searchString, setSearchString] = useState("");
	const [searchMode, setSearchMode] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reasons, setReasons] = useState<any>([]);
	const searchRef = useRef(null);
	const isModeratorAdmin: boolean = useAdminAuthorized("isModeratorAdmins");

	const getViolations = () => {
		try {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/master-violations/get-all`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					if (res.status !== 200) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setReasons(data);
				})
				.catch((err) => {
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};
	useEffect(() => {
		getViolations();
	}, []);

	const {
		rowData,
		setRowData,
		getAllUsers,
		handleUsersReset,
		violationType,
		setViolationType,
		searchUserName,
		setSearchUserName,
		inputRef,
		userType,
		setUserType,
		setPage,
		page, 
	} = useUserModerationFilter();

	const allPostsMenu = [
		{ id: "allUsers", title: "All Users", value: "" },
		{ id: "activeUsers", title: "Active Users", value: "ACTIVE" },
		{ id: "suspend", title: "Suspend", value: "SUSPEND" },
		{ id: "inactive", title: "Inactive", value: "INACTIVE" },
	];
	const handleMusicSearch = () => {
		if (searchUserName !== "") {
			setSearchMode(true);

			const inputValue = inputRef?.current?.value;
			setSearchUserName(inputValue);


			handleUsersReset();
		}
	};

	const handleMusicReset = () => {
		setSearchMode(false);

		const inputValue = inputRef?.current?.value;
		setSearchUserName(inputValue);

		handleUsersReset();

		setSearchUserName("");
	};

	useEffect(() => {
		handleUsersReset();
		setPage(0); 
	}, [userType, violationType]);

	useEffect(() => {
		setUserType(""); 
		setViolationType("");
	}, []); 

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if(e.key === "Enter") {
			handleMusicSearch();
		}
	}

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: "1 1 100%" }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Grid container xs={12} spacing={2}>
					<Grid item xs={4.39} className={classes.searchField}>
						<FormControl fullWidth variant="outlined" size="small">
							<OutlinedInput
								id="outlined-adornment-password"
								type="text"
								value={searchUserName}
								sx={{ height: "30px", fontSize: "14px", width: '273px' }}
								onChange={(e) => setSearchUserName(e.target.value)}
								inputRef={searchRef}
								onKeyDown={handleKeyDown}
								endAdornment={
									<>
										{searchMode ? (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMusicReset}
													edge="end"
												>
													<img src={CloseIcon} alt="Reset Search" />
												</IconButton>
											</InputAdornment>
										) : (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMusicSearch}
													edge="end"
												>
													<img
														src={Search}
														style={{ height: "18px", width: "18px" }}
														alt="Search"
													/>
												</IconButton>
											</InputAdornment>
										)}
									</>
								}
								placeholder="Search user name"
								// onKeyDown={handleKeyPress}
								autoComplete="off"
							/>
						</FormControl>
					</Grid>
					<Grid item xs={2}></Grid>
					<Grid item xs={2.27}>
						<FormControl fullWidth>
							<Select
								size="small"
								style={{ paddingLeft: "10px", height: "32.07px", width: '133px' }}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={userType}
								onChange={(e) => setUserType(e.target.value)}
							>
								{/* <MenuItem value="subscriptionStatus" selected>
                                All Posts
								</MenuItem> */}
								{allPostsMenu.map((menu: any) => {
									return <MenuItem disabled={!isModeratorAdmin} value={menu.value}>{menu.title}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2.8}>
						<FormControl fullWidth>
							<Select
								size="small"
								sx={{ px: "10px", height: "32.07px", width: '168px' }}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={violationType}
								onChange={(e) => setViolationType(e.target.value)}
							>
								<MenuItem value="">All Violations</MenuItem>
								{reasons.length > 0 &&
									reasons.map((menu: any) => {
										return (
											<MenuItem value={menu.violationType}>
												{menu.violationType}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			)}
			{numSelected > 0 && (
				<Tooltip title="Delete">
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
}

export default function UsersModerationTable() {
	const [orderBy, setOrderBy] = React.useState<keyof Data>("reportDateTime");
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	// const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	// const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	// const [rows, setRows] = useState<Data[]>([]);
	const [viewPost, setViewPost] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const audioRef = useRef(
		new Audio(`${process.env.REACT_APP_PICTURE_URL}mediaUrl`)
	);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [fetchData, setFetchData] = useState(false);
	const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const {
		userModerationFilter,
		setUserModerationFilter,
		getAllUsers,
		isFilterActive,
		resetUserModerationFilter,
		rowData,
		setRowData,
		hasMore,
		setPageNumber,
		handleUsersReset,
		updateUserPendingCount,
		setUpdateUserPendingCount,
		rowsPerPage,
		setRowsPerPage,
		page, 
		setPage,
	} = useUserModerationFilter();


	useEffect(() => {
		resetUserModerationFilter();
    }, []);

	useEffect(() => {
		handleUsersReset();
		setPage(0); 
	}, [userModerationFilter]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rowData.slice(0, Math.min(rowData.length, 10)).map((n: any) => n.name);
			setSelected(newSelected);
			return;
		} else {
			setSelected([]);
		}
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	useEffect(() => {
		if (fetchData) {
			getAllUsers(page, rowsPerPage);
			setFetchData(false);
		}
	}, [fetchData]);

	const paginatedRows = rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  
	const handleChangePage = (event:any, newPage:any) => {
	  setPage(newPage);
	  setFetchData(true);
	};

	const handleChangeRowsPerPage = (event:any) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setRowData([]); 
	  setPage(0); 
	  setFetchData(true);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

	// const updateUserStatus = (id:any) =>{
	// 	const newData = rowData && rowData.map((obj: any) => {
	// 		if (obj.userProfileId === id) {
	// 			return { ...obj, status: 'DONE' }
	// 		}
	// 		return obj;
	// 	})
	// 	setRowData(newData);
	// }

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					pb: 2,
				}}
				>
					<EnhancedTableToolbar numSelected={selected.length} />
					{rowData.length === 0 && !hasMore ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							height: "70px",
							textAlign: "center",
							// marginTop: "-60px",
						}}
					>
						<div
							style={{
								display: "table-cell",
								verticalAlign: "top",
							}}
						>
							<Typography sx={{ fontSize: "16px !important" }}>
								No users present
							</Typography>
						</div>
					</div>


				) : (
					<TableContainer sx={{ width: "96%", margin: "0 auto", border: "1px solid #c2c2c3" }}>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={dense ? "small" : "medium"}
						>
							<EnhancedTableHead
								numSelected={selected.length}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								rowCount={rowData.length}
							/>
							<TableBody>
								{paginatedRows.map((row: any, index: any) => {
									const isItemSelected = isSelected(row.name);
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<UserView
											row={row}
											isItemSelected={isItemSelected}
											labelId={labelId}
											getAllUsers={getAllUsers}
										// updateUserStatus={updateUserStatus}
										/>
									);
								})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: (dense ? 33 : 53) * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				{hasMore && (
					<TablePagination
						rowsPerPageOptions={[5,15, 25, 50]}
						component="div"
						count={rowData[0]?.allUserCount}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
			{/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
			<br />
			<br />
		</Box>
	);
}
