import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, Button, Divider, DialogActions } from "@mui/material"
import { useState, useEffect } from "react";
import Close from '../../images/Posts/close.svg'
import CreatePostNew from "./CreatePostNew";
import ImageCropper from "../../pages/ImageCropper";
import { useAlertStatus } from "../../context/AlertStatusContext";

const PostImageFromHome = (props: any) => {

    const [postText, setPostText] = useState('');
    const [file, setFile] = useState<any>("");
    const [imagePreviewUrl, setImagePreviewUrl] = useState<any>('');
    const [editOpen, setEditOpen] = useState(false);
    const [result, setResult] = useState("");
    const [openCreate, setOpenCreate] = useState(false);
    const [updatedImage, setUpdatedImage] = useState('')
    const [updatedFile, setUpdatedFile] = useState<any>('')
    const { updateAlertStatus } = useAlertStatus();

    useEffect(() => {
        setPostText(props.propPostText)
    }, [props.propPostText])
    useEffect(() => {
        setResult(props.imagePreviewUrl)
        setImagePreviewUrl(props.imagePreviewUrl)
        props.file && setFile(props.file)
    }, [props])

    const photoUpload = (destFile: any) => {
        const reader = new FileReader();
        const file = destFile;
        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
    }

    const handleOpenEditor = (e: any) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file.type.startsWith('image/')) {
        setResult(URL.createObjectURL(file));
        setImagePreviewUrl(URL.createObjectURL(file))
        setFile(file);
    }
    else{
        updateAlertStatus(true, false, 'Invalid file type');
    }
    };
    const handleCrop = () => {
        setEditOpen(true);
    }
    const handleEditClose = () => {
        setEditOpen(false)
    }
    const handleNextAction = () => {
        setUpdatedImage(imagePreviewUrl)
        setUpdatedFile(file)
        setOpenCreate(true)
        props.onClose();
    }

    const handlePostClose = () => {
        setOpenCreate(false)
    }

    return (
        <>
            <Dialog
                sx={{
                    border: "1px solid #888888",
                    boxShadow: "customColor.dropShadow",
                    borderRadius: "8px",
                    p: 2,
                    minWidth: 600,
                }}
                fullWidth
                maxWidth="sm"
                open={props.open}
            >
                <DialogTitle sx={{ fontWeight: 'bold', fontSize: '24px' }}>Create a post</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'black',
                    }}
                >
                    <img src={Close} alt='close' />
                </IconButton>

                <DialogContent>
                    <Grid container direction={"column"} rowGap={1}>
                        <Typography variant="h6" sx={{ color: '#888888', }}>
                            Add Picture
                        </Typography>

                        <Grid item>
                            <img src={imagePreviewUrl} alt="uploaded" width='100%'
                                style={{ minHeight: '200px', maxHeight: '400px' }} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, my: 1, ml: 3, }}>
                    <Button
                        sx={{ borderRadius: '17px', color: '#0273E6', borderColor: '#0273E6', '&:hover': { background: "#EEF6FF", borderColor: '#0273E6' }, height: '30px' }}
                        size="small"
                        variant="outlined"
                    >
                        <label style={{ cursor: "pointer", }}>
                            <span style={{ color: '#0273E6', fontSize: '14.4px' }}>Change </span>
                            <input
                                id="photo-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => handleOpenEditor(e)}
                            />
                        </label>
                    </Button>
                    <Button
                        sx={{ borderRadius: '17px', color: '#0273E6', borderColor: '#0273E6', '&:hover': { background: "#EEF6FF", borderColor: '#0273E6' }, height: '30px' }}
                        size="small"
                        variant="outlined"
                        onClick={handleCrop}
                    >Crop
                    </Button>
                </Box>


                <Divider variant="middle" light sx={{ my: 1 }} />

                <DialogActions>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, flexDirection: 'row-reverse', mx: 2, mb: 2 }}>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                            onClick={handleNextAction}
                        > Next
                        </Button>
                        <Button
                            sx={{ borderRadius: '17px' }}
                            size="small"
                            variant="outlined"
                            onClick={props.onClose}
                        >Back
                        </Button>
                    </Box>
                </DialogActions>

            </Dialog>

            <CreatePostNew
                open={openCreate}
                handleClose={handlePostClose}
                resetPostDetails={props.getPostDetails}
                postType={'PHOTO'}
                mediaUrl={updatedImage}
                imageInFile={updatedFile}
            />
            <ImageCropper

                open={editOpen}
                onClose={handleEditClose}
                imagePreviewUrl={result}
                setImagePreviewUrl={setImagePreviewUrl}
                setResult={setFile}
                post={true}
                photoUpload={photoUpload}
                cover={false}
            />
        </>
    )
}

export default PostImageFromHome